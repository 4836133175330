/*eslint-disable*/
import React from "react";

// reactstrap components
import { Col, Container, Row } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href="/blogs"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="/contact-us"
              >
                Contact Us
              </a>
            </li>            
            <li>
              <a
                href="/terms-of-use"
                target="_blank"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()} {" "}
          <a
            href="https://con10craze.com"
            target="_blank"
          >
            Con10Craze.com
          </a>
        </div>
      </Container>

      <Row className="notice" style={{wordWrap:"break-word", overflow:"clip", overflowWrap:"break-word", width:"100%"}}>
        <Col className="ml-auto mr-auto text-center">
          <span style={{wordWrap:"break-word", overflow:"clip"}}>This game may be habit-forming or financially risky.Play responsibly.
          </span>
        </Col></Row>
    </footer>
  );
}

export default DarkFooter;
