import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { userService } from "services/user.service";

const AffiliateSection = () => {
    const [data, setData]= useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        userService.getAffiliateStats()
        .then((res) => {
            setData(res);
            console.log(res);
        })
        .catch((err) => {})
    }, []);

    const handleGameClick = (e, rowData) => {
        e.preventDefault();
        navigate("/game/" + rowData["uuid"]);
    }

    const tableData = () => {
        let totalCommission = 0;
        return (
            <Row style={{justifyContent: "center"}}>
                <Col className='ml-auto mr-auto text-center'>
                    <Table className="winning-detail" style={{ }}>
                        <thead>
                        <tr>
                            <th>
                                Game
                            </th>
                            <th>
                                User Email
                            </th>
                            <th>
                                User Name
                            </th>
                            <th>
                                Game Fee
                            </th>
                            <th>
                                My Commission
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((rowData, index) => {
                            totalCommission += rowData["price"] * rowData["commission"]/100;
                            return (
                                <tr>
                                    <td onClick={(e) => handleGameClick(e, rowData)}>
                                        {rowData["name"]}
                                    </td>
                                    <td>
                                        {rowData["email"]}
                                    </td>
                                    <td>
                                        {rowData["first_name"]}
                                    </td>
                                    <td>
                                    ₹{rowData["price"]}
                                    </td>
                                    <td>
                                    ₹{rowData["price"] * rowData["commission"]/100}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <p style={{ fontWeight: "bold" }}>Total Commission: ₹{totalCommission}</p>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" style={{ marginBottom: "30px", overflow: "scroll" }}>
                        {tableData()}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AffiliateSection;