import React, { useState } from "react";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

// reactstrap components
// import {
// } from "reactstrap";


// sections for this page
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Accordion from "./index-sections/Accordion.js";
import { userService } from "services/user.service.js";
import SEO from "utils/seo.js";
import { useNavigate } from "react-router-dom";
import how_to_thumb from "assets/img/how_to_thumb.png";
import con10crazeLogo from '../assets/img/logo_transparent.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const CounterBox = ({ end, label }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the count once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  let threshold = 1;
  let fixedDecimal = 1;
  end = parseInt(end);
  if (label === "Winnings") {
    threshold = 100;
    fixedDecimal = 2;
  } else if (label === "Games") {
    threshold = 10;
  }

  let roundedEnd = end;
  if (threshold > 1) {
    roundedEnd = ( parseInt(end / threshold)).toFixed(fixedDecimal) * threshold;
  }

  return (
    <div ref={ref} className="counter-box">
      <p>{inView ? <CountUp start={0} end={end} duration={2.75} /> : 0}</p>
      <p style={{ marginTop: "0.75rem" }}>{label}</p>
    </div>
  );
};

const Counters = ({ gamesStats }) => {
  return (
    <div className="counters-container">
      {gamesStats.map((counter, index) => (
        <Col className="text-center ml-auto mr-auto" xs="4" style={{ padding: "5px" }}>
          <CounterBox key={index} end={counter.end} label={counter.label} />
        </Col>
      ))}
    </div>
  );
};

function LandingPage() {
  const howToPlayVideoSrc = "https://d78eqrbdvopvl.cloudfront.net/how_to_play.mp4";
  const [artists, setArtists] = useState([]);
  const [gamesStats, setGamesStats] = useState([]);
  const navigate = useNavigate();


/*  const users = [
    {
      id: 1,
      profilePicture: 'path/to/profile1.jpg',
      name: 'A',
      amount: 5000
    },
    {
      id: 2,
      profilePicture: 'path/to/profile2.jpg',
      name: 'B',
      amount: 7000
    },
    {
      id: 3,
      profilePicture: 'path/to/profile2.jpg',
      name: 'C',
      amount: 3400
    },
    {
      id: 4,
      profilePicture: 'path/to/profile2.jpg',
      name: 'D',
      amount: 2300
    },
    // Add more users as needed
  ];
  const [displayedUsers, setDisplayedUsers] = useState(users.slice(0, 3));
  const [animationClass, setAnimationClass] = useState('');*/


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

/*    const interval = setInterval(() => {
      setAnimationClass('animate');
      setTimeout(() => {
        setDisplayedUsers(prevUsers => {
          const nextIndex = (users.findIndex(u => u.id === prevUsers[prevUsers.length - 1].id) + 1) % users.length;
          const newUsers = users.slice(nextIndex, nextIndex + 3);
          return newUsers.length === 3 ? newUsers : [...newUsers, ...users.slice(0, 3 - newUsers.length)];
        });
        setAnimationClass('');
      }, 1000);
    }, 3000); // Change rows every 3 seconds

*/





userService.getGamesStats()
    .then((res) => {
      setGamesStats(res);
    })
    .catch((err) => {})

    if (userService.isLoggedIn()) {
      userService.getUserWalletDetails();
      // also redirect logged in users to fantasy games page to see al games?
    }
    userService.getFantasyArtists()
      .then((res) => {
        setArtists(res);
      })
      .catch((err) => { })
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
//      clearInterval(interval);
    };
  }, []);


  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    flexWrap: 'wrap', // Allow wrapping of divs
    padding: "5px",
  };

  const divStyles = {
    position: 'relative',
    width: '100%',
    height: '140px',
    backgroundColor: '#f0f0f0', // Grey background color
    border: '1px solid #dddddd',
    borderRadius: '10px', // Rounded corners
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginBottom:"20px",// Margin between divs for spacing
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" /* Shadow for 3D effect */
  };

  const numberCircleStyles = {
    position: 'absolute',
    top: '-15px', // Adjust to be half inside and half outside
    left: '15px', // Adjust to be half inside and half outside
    width: '30px',
    height: '30px',
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    border: '1px solid #dddddd',
  };


  const containerStylesPoint = {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    width: "100%",
    margin: "auto",
    borderRadius:"5px",
    boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.1)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "14px",
  };

  const infoCardStyles = {
    // backgroundColor: '#f5f5f5',
    // border: '1px solid #dddddd',
    // borderRadius: '10px',
    margin: '10px',

    width: '100%',
    textAlign: 'center',
  };

  const headerStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '20px',
    padding: "5px"
  };

  const detailStyles = {
    fontSize: '16px',
    color: '#4D4D4d',
    marginBottom: '10px',
    padding: "10px"

  };

  const formulaStyles = {
     fontSize: '18px',
    //    color: '#4CAF50',
    fontWeight: 'bold',
    marginBottom: '20px',
  };
  const referralId = "ABC123"; // Replace with your dynamic referral ID

  const handleCopy = () => {
    navigator.clipboard.writeText(referralId);
    alert('Referral ID copied to clipboard!');
  };

  const handleInvite = () => {
    // Add your invite functionality here
    alert('Invite button clicked!');
  };
  const ScoreInformation = () => {
    return (
      <div style={containerStylesPoint}>
        <div style={infoCardStyles}>
          <div style={headerStyles}><p style={{ fontSize: "18px", fontWeight: "bold" }}>How Your Score is Calculated</p></div>
          <div style={detailStyles}>Each Like: <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>1 point</span></div>
          <div style={detailStyles}>Each Comment: <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>2 points</span></div>
          <div style={formulaStyles}>
            Creator Score = <br />
            (1*<span style={{ color: '#4CAF50' }}>{'Likes'}</span> + 2*<span style={{ color: '#4CAF50' }}>{'Comments'}</span>) / <span style={{ color: '#4CAF50' }}>{'Followers'}</span>*100
          </div>
          <div style={detailStyles}>
            Your score is calculated as the sum of scores of the creators in your team.
          </div>
        </div>
      </div>
    );
  };

  const freePlay = () => {
    return (
      <div className="section section-basic" id="basic-elements" style={{    color: "black",padding: "5px" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center">
              <Row>
              <div style={containerStyles}>
              <div className="free-landing-page" style={containerStyles}>
                    <Col style={{marginTop:"5px"}} className="free-column" xs="12" md="6">

                    <div className="promotion-icon">💰</div>
                    <div className="free-text-content">
                        <h2 className="title">Earn Free Cash Bonus Daily</h2>
                        <p>Refer your friends, complete your daily streaks and earn free cash bonus credited to your account instantly!</p>
                      {/*<Row className="mt-3">*/}
                      {/*  <Col xs="4" style={{ color: '#B00610', fontWeight: 'bold',  alignItems: 'left' }}>*/}
                      {/*    {referralId}*/}
                      {/*  </Col>*/}
                      {/*  <Col xs="4">*/}
                      {/*    <ContentCopyIcon className="copy-icon center" onClick={(e) => {*/}
                      {/*      handleCopy(e, "con10craze@okhdfcbank")*/}
                      {/*    }} style={{ cursor: "pointer", width: "auto", fontSize: "25px", }} />*/}

                      {/*  </Col>*/}
                      {/*  <Col xs="4" style={{ color: 'white', fontWeight: 'bold',  alignItems: 'left' }}>*/}
                      {/*    <Button*/}
                      {/*        style={{backgroundColor:"green",marginLeft:"-10px", marginTop:"0px" }}*/}
                      {/*        size="sm"*/}
                      {/*        onClick={handleInvite}*/}
                      {/*    >*/}
                      {/*      Invite*/}
                      {/*    </Button>*/}
                      {/*  </Col>*/}
                      {/*</Row>*/}
                    </div>
                    </Col>
        
                    <Col style={{marginTop:"5px"}} className="free-column" xs="12" md="6">
                      <div className="promotion-icon">🏆</div>
                      <div className="free-text-content">
                        <h2 className="title">Play for Free and Win Big</h2>
                        <p>Use 100% of your Bonuses to Play Our Games for Free. Win real cash and withdraw your winnings effortlessly!</p>
                      </div>
                    </Col>    
                  </div>
                  </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <div className="section section-basic" id="basic-elements" style={{ padding: "5px" }}>

        <SEO
          title="Con10Craze Fantasy Sports Games: Create Dream Teams, Win Real Money, Earn up to INR 1 Lakh referral bonus"
          description="Join Con10Craze's Fantasy Sports Games and craft dream teams of creators and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
          type="website"
          url="https://www.con10craze.com/" />

        <Container>



          <Row >
            <Col className="ml-auto mr-auto text-center" md="8" style={{ padding: "5px" }}>

              <h2 className="title">
                Con10Craze Fantasy Sports
              </h2>
              <p>
                Join Con10Craze, the first Social Fantasy Sports platform in India! Pick Instagram creators as your players, and their engagement rates decide your score. Climb the leaderboard and win big by backing the most engaging influencers!
              </p>

            </Col></Row>
        </Container>
      </div>

      {freePlay()}


      <div className="section section-basic" id="basic-elements" style={{ padding: "5px" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center">

              <h2 className="title" >
              It's super easy to start playing on <span className="text-logo">Con10Craze</span>
              </h2>
              <Row>
                <div style={containerStyles}>
                  <div style={containerStyles}>
                    <Col className="ml-auto mr-auto" xs="12" md="4">
                      <div className="free-text-content" key="1" style={divStyles}>
                        <div style={numberCircleStyles}>1</div>
                        <Col style={{ marginLeft: "10px" }}>
                          <Row style={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop:"10px" }}>
                            <h2 className="title">Select a Game</h2>
                          </Row>
                          <Row style={{ textAlign: "left" }}>
                            <p >Choose upcoming game that you want to play</p>
                          </Row>
                        </Col>
                      </div>
                    </Col>

                    <Col className="ml-auto mr-auto" xs="12" md="4">
                      <div className="free-text-content" key="2" style={divStyles}>
                        <div style={numberCircleStyles}>2</div>
                        <Col style={{ marginLeft: "10px" }}>
                          <Row style={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop:"10px" }}>
                            <h2 className="title">Create your Team</h2>
                          </Row>
                          <Row style={{  textAlign: "left" }}>
                            <p >Use your skills to pick the right creators</p>
                          </Row>
                        </Col>
                      </div>
                    </Col>

                    <Col className="ml-auto mr-auto" xs="12" md="4">
                      <div className="free-text-content"key="3" style={divStyles}>
                        <div style={numberCircleStyles}>3</div>
                        <Col style={{ marginLeft: "10px" }}>
                          <Row style={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop:"10px" }}>
                            <h2 className="title">Participate</h2>
                          </Row>
                          <Row style={{textAlign: "left" }}>
                            <p >Lock in your team and win cash rewards</p>
                          </Row>
                        </Col>
                      </div>
                    </Col>

                  </div>
                </div>
              </Row>
            </Col>
          </Row>

          <Counters gamesStats={gamesStats}/>



          <Row>
            <Col className="ml-auto mr-auto text-center" style={{ padding: "5px" }}>
              <h2 className="title">
                HOW TO PLAY
              </h2>
              <p >
              Watch this video to learn how to play on Con10Craze and get started today!<br></br><br></br>
              {/*Create your team of favourite Instagram creators, compete based on their engagement, and earn cash rewards. Visit the playground for insider tips on creators and boost your chances of winning. Play, compete, and cash in!<br></br><br></br>*/}
              {/*Are you ready to challenge fellow sports fans?*/}
              </p>
              <video id="teaser_vid"
                preload="none"
                frameBorder="0"
                poster={how_to_thumb}
                playsInline
                controls
                width="100%"
                title="How to Play Con10Craze Fantasy Sports"
                style={{ borderRadius: "10px" }}
              >
                <source src={howToPlayVideoSrc} type="video/mp4" />
              </video>
            </Col>
          </Row>

          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8" style={{ padding: "5px" }}>
              <Button
                className="btn-round"
                color="info"
                style={{ fontWeight: "bold" }}
                onClick={(e) => { e.preventDefault(); navigate("/fantasy-games") }}
                size="lg"
              >
                Start Playing Now!
              </Button>
            </Col>
          </Row>

        </Container>
      </div>






      <div>
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" style={{ padding: "15px" }}>
              <ScoreInformation />
            </Col>
          </Row>
        </Container>
      </div>



      <div className="section section-basic" id="basic-elements" style={{ padding: "5px" }}>
        <Container>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="8" style={{ padding: "5px" }}>
              {artists.length > 0 && <h3 className="title">
                Meet Our Creators
              </h3>}
            </Col>

            <div className="scrollmenu" style={{ padding: "5px" }}>
              {artists.map((artist, index) => {
                const artist_engagement = artist.avg_followers > 0 ? (artist.avg_likes + 2 * artist.avg_comments) / (artist.avg_followers) * 100 : 0;
                return (
                  <div>
                    <img className="rounded-circle artist-img"
                        alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                        src={artist["profile_img"]}
                    ></img>
                    <a className="artist-name"
                      href={"https://instagram.com/" + artist.social_media_handle}
                      target="_blank" rel="noopener noreferrer"
                      style={{
                        display: "flex",
                        fontSize: "initial",
                        justifyContent: "center",
                        textAlign: "center",
                        background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                        backgroundClip: "text",
                        color: "transparent"
                      }}>
                      {artist.name}</a>
                    <p style={{ fontWeight: "bold", fontSize: "initial", marginBottom: "0px", color: "black" }}>
                      {formatter.format(artist.avg_followers)} Followers
                    </p>
                    <p style={{ color: "green", fontWeight: "bold" }}>
                      <i className="now-ui-icons media-2_sound-wave" style={{ marginRight: "0.25rem" }}></i>
                      {artist_engagement.toFixed(2)}
                    </p>
                  </div>

                )
              })}
            </div>

          </Row>

          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8" style={{ padding: "5px" }}>
              <Button
                className="btn-round"
                color="info"
                style={{ fontWeight: "bold" }}
                onClick={(e) => { e.preventDefault(); navigate('/signup?artist=true'); }}
                size="lg"
              >
                Are you a Creator?
              </Button>
            </Col>
          </Row>

        </Container>
      </div>






{/*
      <div className="section section-basic">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto">
              <h3>Latest Winning Results</h3>



              <div class="table-container">
                {users.map((user, index) => (
                  <div class="table-row">
                    <div class="profile-wrapper">
                      <div class="profile-picture">
                        <img src={con10crazeLogo} alt="Profile Picture" />
                      </div>
                    </div>
                    <div class="user-name">John Doe</div>
                    <div class="logo-icon">
                      <img src={con10crazeLogo} alt="Con10Craze Logo" />
                    </div>
                    <div class="user-info">
                      <div class="amount">
                        <div class="amount-value">₹5000</div>
                        <div class="amount-text">Winning Amount</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>*/}


{/*
              <div className="user-table-container">
      {displayedUsers.map((user, index) => (
        <div key={user.id} className={`user-row ${animationClass} row-${index + 1}`}>
            <div className="profile-wrapper">
              <img src={`https://via.placeholder.com/50?text=${user.name[0]}`} alt={user.name} className="profile-image" />
            </div>
            <span className="user-name">{user.name}</span>
            <div class="logo-icon">
                      <img src={con10crazeLogo} alt="Con10Craze Logo" />
                    </div>
            <div className="user-details">
              <span className="user-amount">₹{user.amount}</span>
              <span className="winning-amount">Winning Amount</span>
            </div>
        </div>
      ))}
    </div>

            </Col>
          </Row>

        </Container>
      </div>*/}






      <div className="section section-basic" style={{ padding: "5px" }}>
        <Container>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h3 className="title">
              Frequently Asked Questions
            </h3>
          </Col>
          <Accordion />

        </Container>
      </div>

    </div>
  );
}

export default LandingPage;

