import { Button, Col, Row } from "reactstrap";

import { userService } from "../services/user.service";

const GoogleLogin = ({errors, setErrors, redirectTo, userType, referralId}) => {
    const gLogin = (e) => {
        e.preventDefault();
        let redirect_to = "/";
        if (redirectTo !== undefined && redirectTo !== null && redirectTo.trim().length > 0) {
            redirect_to = redirectTo.trim();
        }
        
        if (userType !== undefined && userType !== null && userType.trim().length > 0) {
            userType = userType.trim();
        } else {
            userType = "User";
        }

/*        if (userType === "Creator" && (instagramHandle === null || instagramHandle === undefined || instagramHandle.trim().length === 0)) {
            setErrors((prevState) => {
                return {
                  ...prevState,
                  ['instagramHandle']: 'Please enter your Instagram handle.'
                };
              });
              return;       
        }

        if (instagramHandle === undefined || instagramHandle === null || instagramHandle.trim().length === 0) {
            instagramHandle = "";
        }*/

        if (referralId === undefined || referralId === null || referralId.trim().length === 0) {
            referralId = "";
        }

        userService.loginG(redirect_to, userType, referralId)
            .then((res) => {
                window.location.assign(res);
            })
            .catch((err) => {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['glogin']: err.message
                    };
                });
            })
    }

    return (
        <div className="login">
            <Row>
            <Col className="ml-auto mr-auto">

                <Button className="login-with-google-btn" onClick={gLogin}>
                    Sign in with Google
                </Button>
                {errors.glogin && <p style={{
                    display: "flex", color: "red", justifyContent: "center",
                    alignItems: "center", margin: "2px"
                }}>{errors.glogin}</p>}
                </Col>
            </Row>

            <div className="separator" ><h7>OR</h7></div>


        </div>
    )

}

export default GoogleLogin;
