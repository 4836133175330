import ReactGA from 'react-ga';

export function loginConversionTag(){
    var dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('event', 'conversion', {'send_to': 'AW-11139362279/OUUPCJ2G6rgZEOfb1L8p'})
}

export function gameJoinedConversionTag(game_payment){
    var dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('event', 'conversion', {'send_to': 'AW-11139362279/OUUPCJ2G6rgZEOfb1L8p'})
    gtag('event', 'conversion', {
        'send_to': 'AW-11139362279/UqBHCInB7bgZEOfb1L8p',
        'value': game_payment,
        'currency': 'INR',
        'transaction_id': ''
    });
}

export function pixelConversionTracking(){
    if (window.fbq === undefined || window.fbq === null) {
        return;
    }
    window.fbq('track', 'purchase', {currency: "USD", value: 30.00});
}

export function useGAEventsTracker(category = "Event Category") {
    const trackEvent = ( action = "action", label = "label") => {
        ReactGA.event({ category, action, label });
    };
    return trackEvent;
}