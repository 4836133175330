import React, {useEffect, useState} from "react";
import {
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown
} from "reactstrap";

const CreatorsStats = () => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });

    const styles1 = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: "20px"
        },
        imageContainer: {
            overflowY: "scroll",
            overflowX: "hidden",
            alignItems: 'center',
            position: 'relative',
            width: "100%",
            padding: "10px"
        },
        circle: {
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            overflow: 'hidden',
            margin: '0 10px',
            cursor: 'pointer',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }
    };
    const styles = {
        container: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
        card: {
            position: 'relative',
            width: '300px',
            height: '500px',
            borderRadius: '15px',
            background: 'linear-gradient(135deg, #2c3e50, #8e44ad)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '80px',
            zIndex: 1, // Ensure main card is on top
        },
        polygon: {
            position: 'absolute',
            top: '25px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '150px',
            height: '150px',
            backgroundColor: '#8e44ad',
            clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1, // Ensure image is on top
        },
        image: {
            marginTop: "15px",
            width: '120px',
            height: '120px',
            borderRadius: '50%',
        },
        nameField: {
            marginTop: '120px',
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            zIndex: 1, // Ensure text is on top
        },
        statsContainer1: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '40px',
        },
        statsContainer2: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '75%',
            marginTop: '20px',
        },
        span: {
            color:"darkgrey",
            fontWeight:"bold",
            fontSize:"8px"
        },
        circle: {
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            background: 'linear-gradient(135deg, #8b7500, #d4af37, #ffd700)', // Balanced deep and bright golden gradient

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        slantCardLeft: {
            position: 'absolute',
            top: '50%',
            left: '-100%',
            transform: 'translateY(-50%) rotate(-30deg)',
            width: 'calc(100vw + 300px)',
            height: '300px',
            backgroundColor: '#ff7f50',
            borderRadius: '15px',
            zIndex: 0, // Ensure left card is behind
        },
        slantCardRight: {
            position: 'absolute',
            top: '50%',
            right: '-100%',
            transform: 'translateY(-50%) rotate(30deg)',
            width: 'calc(100vw + 300px)',
            height: '300px',
            backgroundColor: '#90ee90',
            borderRadius: '15px',
            zIndex: 0, // Ensure right card is behind
        },
    };

    const [dataLoaded, setDataLoaded] = useState(0);
    const [selectedCreator, setSelectedCreator] = useState(0);
    const [insightsData, setInsightsData] = useState([]);
    const [topData, setTopData] = useState([]);
    const [bottomData, setBottomData] = useState([]);
    let midPoint = 0;
    const handleImageClick = (index) => {
        console.log(index)
        setSelectedCreator(index);
    };

    React.useEffect(() => {
        setInsightsData([
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            }, {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            }
            , {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            }
            , {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            },
            {
                "name": "Nikhil Suri",
                "profile_pic": "https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg",
                "likes": 100000,
                "comments": 100000,
                "score": 0.5,
                "followers": 10000000,
                "selected_by": "5%",
            }


        ])
        midPoint = insightsData.length / 2
        console.log(insightsData)
        let topRow = []
        let bottomRow = []
        for (let i = 0; i < insightsData.length; i++) {
            if (i <= midPoint) {
                topRow.push(insightsData[i]);
            } else {
                bottomRow.push(insightsData[i]);
            }
        }
        setTopData(topRow)
        setBottomData(bottomRow)
        setDataLoaded(1)
    }, []);


    if (!dataLoaded) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (dataLoaded) {
        return (

            <Container style={{padding: "0px"}}>
                <Row>
                    <Col className="ml-auto mr-auto" style={{padding: "0px"}}>
                        <div style={styles1.container}>
                            <div className="scrollmenu" style={{padding: "5px"}}>
                                {topData.map((index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleImageClick(index)}
                                            style={{
                                                ...styles1.circle,
                                                border: selectedCreator === index ? '5px solid #ffd700' : '2px solid transparent',
                                            }}
                                        >
                                            <img
                                                src="https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg"
                                                alt={`Image ${index}`}
                                                style={styles1.image}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>


                        <div style={styles.container}>
                            <div style={styles.card}>
                                <div style={styles.polygon}>
                                    <img
                                        src={insightsData[selectedCreator].profile_pic}
                                        alt="Profile"
                                        style={styles.image}
                                    />
                                </div>
                                <div style={styles.nameField}>{insightsData[selectedCreator].name}</div>
                                <div style={styles.statsContainer1}>
                                    <div className="center">
                                        <div style={styles.circle}>{formatter.format(insightsData[selectedCreator].followers)}</div>
                                        <span aria-hidden="true" style={styles.span}>FOLLOWERS</span>
                                    </div>
                                    <div className="center">
                                        <div style={styles.circle}>{formatter.format(insightsData[selectedCreator].likes)}</div>
                                        <span aria-hidden="true" style={styles.span}>LIKES</span>
                                    </div>
                                    <div className="center">
                                        <div style={styles.circle}>{formatter.format(insightsData[selectedCreator].comments)}</div>
                                        <span aria-hidden="true" style={styles.span}>COMMENTS</span>
                                    </div>
                                </div>
                                <div style={styles.statsContainer2}>
                                    <div className="pull-right">
                                        <div style={styles.circle}>{insightsData[selectedCreator].selected_by}</div>
                                        <span aria-hidden="true" style={styles.span}>SEL BY</span>
                                    </div>
                                    <div className="pull-left">
                                        <div style={{...styles.circle, color:"black", background: 'linear-gradient(135deg, #8B0000,#8B0000, #D4AF37, #FFD700)', // Deep red to golden gradient
                                        }}>{insightsData[selectedCreator].score}</div>
                                        <span aria-hidden="true" style={{...styles.span}}>SCORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={styles1.container}>
                            <div className="scrollmenu" style={{padding: "5px"}}>
                                {bottomData.map((index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleImageClick(index)}
                                            style={{
                                                ...styles1.circle,
                                                border: selectedCreator === index ? '5px solid #ffd700' : '2px solid transparent',
                                            }}
                                        >
                                            <img
                                                src="https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg"
                                                alt={`Image ${index}`}
                                                style={styles1.image}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        )
    }
}

export default CreatorsStats;