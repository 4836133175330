import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { userService } from "services/user.service";


const insights = [
    { icon: "now-ui-icons business_chart-bar-32", name: 'Number of Games involved in', key: 'num_games', color: "blue" },
    { icon: "now-ui-icons users_single-02", name: 'Selected by Players', key: 'teams', color: "orange" },
    { icon: "now-ui-icons business_chart-pie-36", name: 'Number of Games won', key: 'games_won', color: "red" },
    { icon: "now-ui-icons sport_trophy", name: 'Earnings from Games', key: 'earnings', color: "green" },
];

const ArtistInsights = () => {
    const [insightsData, setInsightsData] = useState({});

    useEffect(() => {
        userService.getArtistInsights()
        .then((res) => {
            let data = {};
            if ("num_games" in res) {
                data["num_games"] = res["num_games"];
            }
            if ("games_won" in res) {
                data["games_won"] = res["games_won"];
            }
            if ("earnings" in res) {
                data["earnings"] = res["earnings"];
            }
            if ("teams" in res) {
                data["teams"] = res["teams"];
            }
            setInsightsData(data);
        })
        .catch((err) => {})
    }, []);

    return (
        <div>
            <Container>
                <Row>
                    {insights.map((insight, index) => (
                        <Col className="ml-auto mr-auto" sm="6">
                            <div key={index} className="insights-grid-item">
                                <i className={insight.icon} style={{ color: insight.color }} ></i>
                                <div className="insight-details">
                                    <div className="insight-name">{insight.name.toUpperCase()}</div>
                                    <div className="insight-value" style={{ color: insight.color }}>{insightsData[insight.key]}</div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default ArtistInsights;