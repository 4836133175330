import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Alert
} from "reactstrap";

// core components
import { userService } from "services/user.service";
import { isValidEmail, isValidString } from '../utils/helper';
import { loginConversionTag } from "./conversion-script";
import GoogleLogin from 'components/GoogleLogin';
import SEO from "utils/seo";

function LoginPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const location = useLocation();
  const [redirectTo] = React.useState(location.state ? "redirectTo" in location.state ? location.state["redirectTo"] : null : null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (userService.isLoggedIn()) {
      if (isValidString(redirectTo)) {
        navigate(redirectTo);
      } else {
        navigate('/');
      }
    }
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChange = (event, name) => {
    const value = event.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
    setErrors((prevState) => {
      return {
        ...prevState,
        [name]: null
      };
    });
  }

  const isValidEmailEntry = () => {
    if (inputs.emailaddress === undefined || inputs.emailaddress === null || inputs.emailaddress.trim().length === 0) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['emailaddress']: 'Please enter email address.'
        };
      });
      return false;
    }
    if (!isValidEmail(inputs.emailaddress)) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['emailaddress']: 'Please enter a valid email address.'
        };
      });
      return false;
    }
    return true;
  }

  const isValidPassword = () => {
    if (inputs.password === undefined || inputs.password === null || inputs.password.trim().length === 0) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['password']: 'Please enter a valid password.'
        };
      });
      return false;
    }
    return true;
  }

  const handleSubmitClick = (event) => {
    event.preventDefault();
    loginConversionTag();
    setErrors({});
    if (!isValidEmailEntry()) {
      return;
    }
    if (!isValidPassword()) {
      return;
    }
    var params = { 'email': inputs.emailaddress, 'password': inputs.password };
    userService.login(params)
      .then((res) => {
        if (isValidString(redirectTo)) {
          navigate(redirectTo, { state: res });
        } else {
          navigate('/', { state: res });
        }
      })
      .catch((error) => {
        setErrors((prevState) => {
          return {
            ...prevState,
            ['login']: error.message
          };
        });
      });
  }

  const handleSignup = (event) => {
    event.preventDefault();
    navigate({ pathname: '/signup', search: searchParams.toString(), state: location.state });
  }

  return (
    <div className="page-header clear-filter" filter-color="blue">
      <div className="content">
        <SEO
                title="Secure Log in to Your Account | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Access your account and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores!"
                type="website"
                url="https://www.con10craze.com/login" />

        <Container>
          <Col className="ml-auto mr-auto" md="4" style={{ marginTop: "125px" }}>
            <Card className="card-login card-plain">
              <Form action="" className="form" method="">
                <CardBody>

                  <GoogleLogin errors={errors} setErrors={setErrors} redirectTo={redirectTo} />

                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter your email address"
                      type="email"
                      value={inputs.emailaddress}
                      onChange={e => handleChange(e, "emailaddress")}
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    ></Input>

                    <Alert color="danger" isOpen={errors.emailaddress !== null && errors.emailaddress !== undefined}>
                      <Container>                        
                        {errors.emailaddress}
                      </Container>
                    </Alert>
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_lock-circle-open"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      value={inputs.password}
                      onChange={e => handleChange(e, "password")}
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    ></Input>

                    <Alert color="danger" isOpen={errors.password !== null && errors.password !== undefined}>
                      <Container>
                        {errors.password}
                      </Container>
                    </Alert>

                  </InputGroup>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    onClick={handleSubmitClick}
                    size="lg"
                  >
                    Log In
                  </Button>

                  <Alert color="danger" isOpen={errors.login !== null && errors.login !== undefined}>
                      <Container>
                        {errors.login}
                      </Container>
                    </Alert>
                  <div className="pull-center">
                    <Button className="btn-link" color="danger" size="lg" onClick={() => navigate('/forgot-password')}>Forgot Password?
                    </Button>
                  </div>
                  <div className="pull-center">
                    <h6 style={{
                              fontStyle: "italic",
                          }}>Don't have an account?
                          <Button className="btn-link" color="danger" size="lg" onClick={handleSignup}>Sign Up</Button>
                      </h6>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Container>
      </div>
    </div>
  );
}

export default LoginPage;
