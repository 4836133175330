import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, Table, Tooltip } from 'reactstrap';
import Confetti from "react-confetti";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import SEO from '../utils/seo';
import { userService } from 'services/user.service';
import InfoIcon from '@mui/icons-material/Info';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import FantasyGameHader from 'components/Headers/FantasyGameHeader';
import DarkFooter from 'components/Footers/DarkFooter';
import MyModalDialog from './index-sections/MyModalDialog';
import { format } from 'date-fns';
import upi_code from 'assets/img/upi_code.png';

import {copyTextToClipboard} from "../utils/helper";
import {gameJoinedConversionTag} from "./conversion-script";
import Playground from './Playground';
import Scorecard from './Scorecard';


const SortDropdown = ({ items, onSort }) => {
    const [sortOption, setSortOption] = useState('name');
  
    const handleSortChange = (e) => {
      setSortOption(e.target.value);
      onSort(e.target.value);
    };
  
    return (
      <div>
        <select value={sortOption} onChange={handleSortChange}>
          <option value="followers">Sort by followers</option>
          <option value="engagement">Sort by engagement rate</option>
        </select>
      </div>
    );
  };


const FantasyGamePage = () => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const [sortOption, setSortOption] = useState('name');
    const [showPlayersLeaderboard, setShowPlayersLeaderboard] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(true);
    const [userUpiId, setUserUpiId] = useState("");
    const [selectArtistCount, setSelectArtistCount] = useState(0);
    const [set_size, setSetSize] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentRedirect = searchParams.get("paymentRedirect");
    const navigate = useNavigate();
    const { game_external_id } = useParams();
    const [game, setGame] = useState({ "details": {} });
    const [artists, setArtists] = useState([]);
    const [artistsRanking, setArtistsRanking] = useState([]);
    const [topParticipants, setTopParticipants] = useState([]);
    const [winningDistribution, setWinningDistribution] = useState([]);
    const [artistWinningDistribution, setArtistWinningDistribution] = useState([]);
    const [selectedArtists, setSelectedArtists] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [errorHref, setErrorHref] = useState(null);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showPodium, setShowPodium] = useState(false);
    const [booster, setBooster] = useState(1);
    const [maxBooster, setMaxBooster] = useState(1);
    const [showConfetti, setShowConfetti] = useState(false);
    const userData = userService.getLocalStorageData();

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');

    const [iconPills, setIconPills] = React.useState("1");
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenPrize, setTooltipOpenPrize] = useState(false);
    const [artistTooltipOpen, setArtistTooltipOpen] = useState(false);
    const [artisttooltipOpenPrize, setArtistTooltipOpenPrize] = useState(false);

    const [disablePayButton, setDisablePayButton] = useState(true);
    const [payDialogShown, setPayDialogShown] = useState(1);

    // notifications
    const [loginNotify, setLoginNotify] = React.useState(false);
    const [createTeamNotify, setCreateTeamNotify] = React.useState(false);
    const [reviewTeamNotify, setReviewTeamNotify] = React.useState(false);
    const [winningDetails, setWinningDetails] = React.useState(false);
    const [artistWinningDetails, setArtistWinningDetails] = React.useState(false);
    const [gameNotify, setGameNotify] = React.useState(false);
    const [paymentUnderProcessingNotify, setPaymentUnderProcessingNotify] = React.useState(false);
    const [paymentFailedNotify, setPaymentFailedNotify] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        if (game_external_id === undefined || game_external_id === null || game_external_id.trim().length === 0) {
            navigate('/');
        }

        if (userService.isLoggedIn()) {
            userService.getUserWalletDetails();
        }

        fetchGameDetails();
    }, []);

    const fetchGameDetails = () => {
        userService.getFantasyGameDetails(game_external_id)
            .then((res) => {
                let now_time = new Date();
                let start_time = new Date(res["details"]["start_time"]);
                const isUpcoming = start_time > now_time;
                setIsUpcoming(isUpcoming);
                setGame(res);

                setTitle(res['details']["name"] + " | Con10Craze Fantasy Sports");
                setUrl("https://con10craze.com/game/" + game_external_id);

                let newSelectedArtists = [];
                let selectArtistCountTemp = 0;
                let allArtists = []
                for (var i = 0; i < res["artists"].length; ++i) {
                    allArtists.push(res["artists"][i])
                    allArtists[i].selected = false;
                }
                if ("max_booster" in res['details'] && res["details"]["max_booster"] > 1) {
                    setMaxBooster(res['details']["max_booster"]);
                }
                if ("user_team" in res && res["user_team"].length > 0) {
                    selectArtistCountTemp = res["user_team"].length;
                    if ("booster" in res['user_details'] && res["user_details"]["booster"] > 1) {
                        setBooster(res['user_details']["booster"]);
                    }
                    for (var i = 0; i < res["user_team"].length; ++i) {
                        newSelectedArtists.push({
                            "default": false, "uuid": res["user_team"][i].uuid,
                            "profile_img": res["user_team"][i].profile_img, "name": res["user_team"][i].name
                        });

                        allArtists = allArtists.map((artist) => {
                            if (artist["uuid"] === res["user_team"][i].uuid) {
                                const updatedArtist = {
                                    ...artist,
                                    selected: true,
                                };
                                return updatedArtist;
                            }
                            return artist;
                        });
                    }
                    // showConfettiFn();
                } else {
                    for (var i = 0; i < res["details"]["team_size"]; ++i) {
                        newSelectedArtists.push({ "default": true });
                    }

                }

                if ("status" in res["user_details"]) {
                    if (res["user_details"]["status"] === "CREATED") {
                        setPaymentUnderProcessingNotify(true);
                    } else if (res["user_details"]["status"] === "FAILED") {
                        setPaymentFailedNotify(true);
                    }
                }

                if (!userService.isLoggedIn()) {
                    setCreateTeamNotify(true);
                    setLoginNotify(true);
                } else {
                    let now_time = new Date();
                    let particpation_time = new Date(game["details"]["participation_time"]);
                    if (particpation_time > now_time) {
                        if (selectArtistCountTemp === 0) {
                            setCreateTeamNotify(true);
                        } else if (selectArtistCountTemp > 0) {
                            setGameNotify(true);
                            setCreateTeamNotify(false);
                        }
                    }
                }
                setArtists(allArtists);
                setSetSize(newSelectedArtists.length);
                setSelectedArtists([...newSelectedArtists]);
                setSelectArtistCount(selectArtistCountTemp);
                setTopParticipants(res["leaderboard"]);
                if ( res["status"] === "completed") {
                        setShowPodium(true)
                }
            })
            .catch((err) => {
            })

        if (userService.isLoggedIn()) {
            userService.getPlayersLeaderboard(game_external_id)
                .then((res) => {
                    if ( res["artist_view"]) {
                        setArtistsRanking(res["ranked_artists"]);
                        setShowPlayersLeaderboard(true);
                    }
                })
                .catch((err) => {
                })
        }

    }

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const toggleTooltipPrize = () => setTooltipOpenPrize(!tooltipOpenPrize);
    const toggleArtistsTooltip = () => setArtistTooltipOpen(!artistTooltipOpen);
    const toggleArtistsTooltipPrize = () => setArtistTooltipOpenPrize(!artisttooltipOpenPrize);

    const handleUserUpiIdChanges = (e) => {
        e.preventDefault();
        setUserUpiId(e.target.value);
    }

    const showConfettiFn = () => {
        setShowConfetti(true);
        fetchGameDetails();
        setTimeout(function () {
            setShowConfetti(false);
        }, 7500);
    }

    const handleTabChange = (e, tabIndex) => {
        e.preventDefault();
        setIconPills(tabIndex);
    };

    const gotoLogin = () => {
        navigate('/login', { state: { "redirectTo": "/game/" + game_external_id } });
    }

    const showWinners = () => {


        if (topParticipants && topParticipants.length <3) {
            return (
                <Row></Row>
            )
        }
        return (

            <Row className="text-center justify-content-center"  style={{ width:"100%",padding:"20px", margin:"auto"}}>
                    <div className="podium">
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[1].first_name}</p>
                            <div className="podium__rank second">2</div>
                        </div>
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[0].first_name}</p>
                            <div className="podium__rank first">1</div>
                        </div>
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[2].first_name}</p>
                            <div className="podium__rank third">3</div>
                        </div>
                    </div>
            </Row>
        )
}
    const notifyForLogin = () => {
        return (
            <div className="game-popup">

                <Alert color="success" isOpen={loginNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons ui-2_favourite-28"></i>
                        </div>
                        {' '}<a className="alert-link" style={{ cursor: "pointer" }} onClick={gotoLogin}>Log In now and start playing Fantasy Sports Games.</a>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setLoginNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
            </div>
        )
    }

    const notifyToCreateTeam = () => {
        return (
            <div className="game-popup">

                <Alert color="success" isOpen={createTeamNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons ui-1_simple-add"></i>
                        </div>
                        Select {set_size} Creators to create your team<br></br><strong>({selectArtistCount} out of {set_size} selected)</strong>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setCreateTeamNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
            </div>
        )
    }

    const clearSelection = () => {
        let newSelectedArtists = [...selectedArtists];
        let newArtists = [...artists];
        for (var i = 0; i < selectedArtists.length; ++i) {
            newSelectedArtists[i].default = true;
            newSelectedArtists[i].profile_img = "";
            newSelectedArtists[i].uuid = null;
            newSelectedArtists[i].name = null;
        }
        for (var i = 0; i < artists.length; ++i) {
            newArtists[i].selected = false;
        }
        setSelectArtistCount(0);
        setArtists(newArtists);
        setSelectedArtists(newSelectedArtists);
        setReviewTeamNotify(false);
        setWinningDetails(false);
        setArtistWinningDetails(false);
        setErrorMsg(null);
    }

    const handleClipBoardClick = (e, data) => {
        e.preventDefault();
        copyTextToClipboard(data);
    }

    const makeRazorpayPayment = (e, totalPrice, accountUnutilizedTaken, bonusTaken) => {
        e.preventDefault();
        setCreateTeamNotify(false);
        const selectedArtistsUuids = selectedArtists.map((selectedArtist) => {
            return selectedArtist["uuid"];
        });

        userService.makeFantasyGamePayment(game_external_id, selectedArtistsUuids, booster, totalPrice, 
            accountUnutilizedTaken, bonusTaken, userUpiId)
        .then((res) => {
            setReviewTeamNotify(false);
            setPaymentUnderProcessingNotify(true);
            setGameNotify(true);
            showConfettiFn();
            gameJoinedConversionTag(totalPrice);
        })
        .catch((err) => {
        })
    }

    const winningDetailsView = () => {
        return <MyModalDialog title="" show={winningDetails} onClose={() => setWinningDetails(false)} >
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h2>Winnings Distribution</h2>
                    {/*<h3>{game["details"]["name"]}</h3>*/}
                </Col>
            </Row>
            <Row style={{justifyContent: "center"}}>
                <div>
                    <Col className='ml-auto mr-auto text-center'>
{/*                        <p>Prize Pool #Spots: </p>*/}

                        <Table id="winningDetails" className="winning-details" width="100%">
                            <thead>
                            <tr>
                                <th>
                                    Rank
                                </th>
                                <th>
                                   Winning Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {winningDistribution.map((rowData, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>
                                        ₹{rowData.toLocaleString()}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </Row>

        </MyModalDialog>



    }

    const artistWinningDetailsView = () => {
        return <MyModalDialog title="" show={artistWinningDetails} onClose={() => setArtistWinningDetails(false)} >
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h2>Creators Winnings Distribution</h2>
                </Col>
            </Row>
            <Row style={{justifyContent: "center"}}>
                <div>
                    <Col className='ml-auto mr-auto text-center'>
                        <Table id="artistWinningDetails" className="winning-details" width="100%">
                            <thead>
                            <tr>
                                <th>
                                    Rank
                                </th>
                                <th>
                                   Winning Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {artistWinningDistribution.map((rowData, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>
                                            {rowData}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </Row>
        </MyModalDialog>
    }

    const togglePayButton = () => {
        setDisablePayButton(!disablePayButton);
    }

    const reviewTeam = () => {
        let askForPayment = game["details"]["type"] === "main" && game["details"]["price"] > 0;
        let totalPrice = game["details"]["price"] * booster;
        const accountUnutilized = 'account_unutilized' in userData ? userData['account_unutilized'] : 0;
        const bonus = 'bonus' in userData ? userData['bonus'] : 0;

        const showBonusTaken = bonus > 0 && totalPrice > 0;
        let bonusTaken = totalPrice >= bonus ? bonus: totalPrice;
        if (showBonusTaken === true) {
            totalPrice = totalPrice - bonusTaken;
        } else {
            bonusTaken = 0;
        }

        const showAccountUnutilizedTaken = accountUnutilized > 0 && totalPrice > 0;
        let accountUnutilizedTaken = totalPrice >= accountUnutilized ? accountUnutilized: totalPrice;
        if (showAccountUnutilizedTaken === true) {
            totalPrice = totalPrice - accountUnutilizedTaken;
        } else {
            accountUnutilizedTaken = 0;
        }

        askForPayment = askForPayment && totalPrice > 0;

        return (
            <MyModalDialog title="" show={reviewTeamNotify} onClose={clearSelection}>

                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" >
                    <div >

                        <div className={"carousel-item" + (payDialogShown === 1 ? " active": "")}>
                            <Row >
                                <Col className="ml-auto mr-auto text-center">
                                    <h2>Review Your Team</h2>
                                    <h3 style={{ marginBottom: "0px" }}>{game["details"]["name"]}</h3>
                                </Col>
                            </Row>

                            <Row>
                                {selectedArtists.map((artist, index) => {
                                    return (
                                        <Col className="ml-auto mr-auto text-center" xs="6" md="4">
                                            <Card className="card-login card-plain" style={{ marginTop: "30px", marginBottom: "0px" }}>
                                                <CardHeader className="text-center">
                                                    <div className="logo-container">
                                                        <img
                                                            alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                                                            src={artist["profile_img"]}
                                                            style={{ borderRadius: "10px" }}
                                                            height="100"
                                                        ></img>
                                                        <h4 className="artist-name"
                                                            style={{
                                                                display: "flex",
                                                                fontSize: "initial",
                                                                justifyContent: "center",
                                                                textAlign: "center",
                                                                background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                                backgroundClip: "text",
                                                                color: "transparent"
                                                            }}>
                                                            {artist.name}</h4>
                                                    </div>
                                                </CardHeader>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>

                            {askForPayment &&
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Game Price:</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>₹{game["details"]["price"]}</h5>
                                    </Col>

                                    {showBonusTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Bonus:</h5>
                                    </Col>}
                                    {showBonusTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>- ₹{bonusTaken}</h5>
                                    </Col>}

                                    {showAccountUnutilizedTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Amount already deposited:</h5>
                                    </Col>}
                                    {showAccountUnutilizedTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>- ₹{accountUnutilizedTaken}</h5>
                                    </Col>}

                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Total Price:</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>₹{totalPrice}</h5>
                                    </Col>

                                    {/*                        <Col className="ml-auto mr-auto text-center" xs="6">
                                            <h5 className='pull-right'>Booster:</h5>
                                        </Col>
                                        <Col className="ml-auto mr-auto text-center" xs="6">
                                            <h5 className='pull-left'>{booster}</h5>
                                        </Col>

                                        <Col className="ml-auto mr-auto text-center" xs="12">
                                            <FormGroup>
                                                <Input className="ml-auto mr-auto text-center" xs="8"
                                                    type="range"
                                                    defaultValue={1}
                                                    min={1}
                                                    max={maxBooster}
                                                    step={1}
                                                    onChange={(e) => setBooster(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

*/}

                                    <Col className="ml-auto mr-auto text-center center" xs="12">
                                        <input type="checkbox" aria-label="Checkbox for following text input" onChange={togglePayButton}></input>
                                        <span id="age-limit-check" aria-hidden="true">&nbsp;&nbsp;I certify that I am at least 18 years old</span>
                                    </Col>

                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg" color="danger" type="button"
                                        disabled={disablePayButton}
                                        onClick={(e) => setPayDialogShown(2)}
                                        >
                                            Pay Now</Button>
                                </Row>
                            }

                            {!askForPayment &&
                                <Row>
                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg" color="danger" type="button"
                                        onClick={(e) => makeRazorpayPayment(e, totalPrice, accountUnutilizedTaken, bonusTaken)}>Submit</Button>
                                </Row>
                            }
                        </div>

                        <div className={"carousel-item" + (payDialogShown === 2 ? " active": "")}>
                            <div>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center">
                                        <h3 className="title">Complete your Payment</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto ">
                                        <p style={{ fontWeight: "normal", }}>1. Copy the Con10Craze UPI Id</p>
                                        <br />
                                        <br />
                                        <p style={{ fontWeight: "normal", }}>2. Make the payment from any UPI app: </p>
                                        <p style={{ fontWeight:"bolder",color:"green"}}>₹{totalPrice}</p>
                                        <br />
                                        <br />
                                        <p
                                            style={{ fontWeight: "normal", }}>3. Enter your UPI Id from which you are making the payment</p>
                                        <br />
                                        <br />
                                        <p style={{ fontWeight: "normal", }}>4. Click Submit when payment is completed</p>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center center">
                                        <Row className="text-center content-center" style={{ marginLeft: "10%", marginTop: "50%" }}>
                                            <img
                                                alt="Con10Craze UPI code"
                                                src={upi_code}
                                                height="200"
                                            ></img>
                                        </Row>
                                        <Row style={{  marginTop: "1%", justifyContent:"center"}}>
                                            <span style={{ color: "#B00610", fontWeight:"bolder" }}>
                                                con10craze@okhdfcbank
                                            </span>
                                            <ContentCopyIcon className="copy-icon center" onClick={(e) => {
                                                handleClipBoardClick(e, "con10craze@okhdfcbank")
                                            }} style={{ cursor: "pointer", width: "auto", fontSize: "25px", }} />
                                        </Row>

                                    </Col>

                                </Row>
                                <Row className="text-center content-center" style={{ margin: "auto", width: "50%", marginTop: "10px" }}>
                                    <input type="text" value={userUpiId} placeholder="Your UPI Id"
                                        onChange={handleUserUpiIdChanges}
                                        className="ml-auto mr-auto text-center field center" style={{ textAlign: "center" }}></input>
                                </Row>
                                <Row>
                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg"
                                        disabled={userUpiId === null || userUpiId === undefined || userUpiId.trim().length < 1}
                                        color="info"
                                        type="button"
                                        onClick={(e) => makeRazorpayPayment(e, totalPrice, accountUnutilizedTaken, bonusTaken)}
                                    >SUBMIT</Button>
                                </Row>
                                <Row>
                                    <span style={{ fontWeight: "lighter", fontSize: "smaller" }}>NOTE: Payment should only be done from the UPI provided above and of ₹{totalPrice}. Platform will not be responsible for any issues with a different UPI id or amount.</span>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">*/}
                {/*    <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
                {/*    <span className="sr-only">Previous</span>*/}
                {/*</a>*/}
                {/*<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">*/}
                {/*    <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
                {/*    <span className="sr-only">Next</span>*/}
                {/*</a>*/}

            </MyModalDialog>
        )
    }

    const notifyToWaitForGame = () => {
        return (
            <div className="game-popup">

                <Alert color="success" isOpen={gameNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons ui-2_like"></i>
                        </div>
                        Congratulations on creating your team! Now, sit back and wait for the game to launch.
                        <button
                            type="button"
                            className="close"
                            onClick={() => setGameNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
            </div>
        )
    }

    const notifyForLeaderboard = () => { }

    const congratsOnTeam = () => {
        if (!userService.isLoggedIn()) {
            setErrorMsg("Log in to get started...");
            setErrorHref("/login");
            setShowErrorDialog(true);
            return;
        }
        let paymentStatus = game["payment_status"];
        if (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID") {
            setErrorMsg("You cannot modify your team after making payment");
            setShowErrorDialog(true);
            return;
        }
        let now_time = new Date();
        let particpation_time = new Date(game["details"]["participation_time"]);
        if (particpation_time < now_time) {
            setErrorMsg("The deadline to participate in this game has ended. Please check other upcoming games.");
            setShowErrorDialog(true);
            return;
        }

        setReviewTeamNotify(true);
    }

    const notifyForPaymentUnderProcessing = () => {
        return (
            <div className="game-popup">

                <Alert color="warning" isOpen={paymentUnderProcessingNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons sport_user-run"></i>
                        </div>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setPaymentUnderProcessingNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                        Your payment is currently being processed and will be updated shortly.<br></br>
                        <p style={{ fontStyle: "italic", fontSize: "small", fontWeight: "400" }}>Please ensure that the payment was made from the UPI ID provided. In case of any issue, please contact us via <a href="mailto:contact-us@con10craze.com" style={{ color: "blue" }}>Email</a> or <a href="https://api.whatsapp.com/send?phone=919380740935" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Whatsapp</a></p>
                    </Container>
                </Alert>
            </div>
        )
    }

    const notifyForPaymentFailed = () => {
        return (
            <div className="game-popup">

                <Alert color="danger" isOpen={paymentFailedNotify} >
                    <Container>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setPaymentFailedNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                        Your previous payment failed. Please create your team and make the payment again.<br></br>
                        <p style={{ fontStyle: "italic", fontSize: "small", fontWeight: "400" }}>If you believe this is a mistake, please contact us via <a href="mailto:contact-us@con10craze.com" style={{ color: "blue" }}>Email</a> or <a href="https://api.whatsapp.com/send?phone=919380740935" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Whatsapp</a></p>
                    </Container>
                </Alert>
            </div>
        )
    }

    const popupNotifications = () => {
        return (
            <>
                {notifyToCreateTeam()}
                {reviewTeam()}
                {notifyToWaitForGame()}
                {notifyForLeaderboard()}
                {winningDetailsView()}
                {artistWinningDetailsView()}
                {notifyForPaymentUnderProcessing()}
                {notifyForPaymentFailed()}
            </>
        )
    }

    const addToTeam = (artistIndex) => {
        if (selectArtistCount === set_size) {
            setErrorMsg("You have already selected your team. Please remove a creator from your team first.");
            setShowErrorDialog(true);
            return;
        }
        let newSelectedArtists = [...selectedArtists];
        for (let i = 0; i < newSelectedArtists.length; ++i) {
            if (newSelectedArtists[i].default === true) {

                newSelectedArtists[i].default = false;
                newSelectedArtists[i].uuid = artists[artistIndex].uuid;
                newSelectedArtists[i].profile_img = artists[artistIndex].profile_img;
                newSelectedArtists[i].name = artists[artistIndex].name;

                let newArtists = [...artists];
                newArtists[artistIndex].selected = !newArtists[artistIndex].selected
                setArtists(newArtists);
                setSelectedArtists(newSelectedArtists);
                let selectArtistCountTemp = selectArtistCount + 1;
                setSelectArtistCount(selectArtistCountTemp);
                if (selectArtistCountTemp === set_size) {
                    congratsOnTeam();
                }
                break;
            }
        }
    }

    const dropFromTeam = (artistIndex) => {
        let newSelectedArtists = [...selectedArtists];
        for (var i = 0; i < newSelectedArtists.length; ++i) {
            if (newSelectedArtists[i].uuid === artists[artistIndex].uuid && newSelectedArtists[i].default === false) {

                newSelectedArtists[i].default = true;
                newSelectedArtists[i].profile_img = "";
                newSelectedArtists[i].uuid = null;
                newSelectedArtists[i].name = null;

                let newArtists = [...artists];
                newArtists[artistIndex].selected = !newArtists[artistIndex].selected
                setArtists(newArtists);
                setSelectedArtists(newSelectedArtists);
                let selectArtistCountTemp = selectArtistCount - 1;
                setSelectArtistCount(selectArtistCountTemp);
                break;
            }
        }
    }

/*    const handleSort = (sortOption) => {
        setItems(items.sort((a, b) => {
          if (sortOption === 'name') {
            return a.name.localeCompare(b.name);
          } else if (sortOption === 'age') {
            return a.age - b.age;
          } else if (sortOption === 'city') {
            return a.city.localeCompare(b.city);
          }
          return 0;
        }));
      };*/

    const artistSelection = (e, index, artistUuid) => {
        if (!userService.isLoggedIn()) {
            setErrorMsg("Log in to get started...");
            setErrorHref("/login");
            setShowErrorDialog(true);
            return;
        }


        // TODO: check if can select artist or not
        let paymentStatus = game["payment_status"];
        if (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID") {
            setErrorMsg("You cannot modify your team after making payment");
            setShowErrorDialog(true);
            return;
        }

        let now_time = new Date();
        let particpation_time = new Date(game["details"]["participation_time"]);
        if (particpation_time < now_time) {
            setErrorMsg("The deadline to participate in this game has ended. Please check other upcoming games.");
            setShowErrorDialog(true);
            return;
        }
        const exists = selectedArtists.some((selectedArtist) => {
            return selectedArtist["uuid"] === artistUuid;
        });
        if (exists) {
            //event to drop
            dropFromTeam(index);
        } else {
            //event to add
            addToTeam(index);
        }
    }
//    const [dropdownOpen, setDropdownOpen] = useState(false);
  //  const toggle = () => setDropdownOpen((prevState) => !prevState);

//    const handleSortChange = (e) => {
  //      setSortOption(e.target.value);
    //}


    const tableContainerStyles = {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // width:"100%",
        //
        // padding: '20px', // Add padding to the container
    };

    const tableStyles = {
//        width: '80%',
        borderCollapse: 'separate',
        borderSpacing: '0 0',
    };

    const headerRowStyles = {
        display: 'flex',
        border: '1px solid #808080',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        backgroundColor: '#ffffff',
        marginBottom: '5px',
        color: "#8811a5"
    };

    const headerCellStyles = {
        padding: '10px',
        textAlign: 'center',
        flex: '1 1 60px', // Ensure each header cell has the same size
        cursor: 'pointer',
        fontWeight: 'bold',
    };

    const selectHeaderCellStyles = {
        ...headerCellStyles,
        visibility: 'hidden'
    };

    const rowStyles = {
        display: 'flex',
        borderLeft: '1px solid #808080',
        borderRight: '1px solid #808080',
        borderTop: '1px solid #808080', // Tiny grey line between rows
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        alignItems: 'center', // Center content vertically
    };

    const firstRowStyles = {
        borderTop: '1px solid #808080',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderTopColor: 'transparent', // Remove the extra grey line on the first row
    };

    const lastRowStyles = {
        borderBottom: '1px solid #808080',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    };

    const cellStyles = {
        padding: '10px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flex: '1 1 100px', // Ensure each cell has the same size as its header
    };

    const nameCellDataStyles = {
        ...cellStyles,
        width:'maxContent',
        maxWidth:'100%',
        flex: '2 1 200px', // Extra width for the name field
    };



    const imageStyles = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    };

    const nameCellStyles = {
        ...headerCellStyles,
        flex: '2 1 200px', // Extra width for the name field
    };



    const spacerCellStyles = {
        flex: '0 0 0px', // Fixed width for spacer cells
    };


    const checkboxStyles = {
        width: '20px',
        height: '20px',
//        appearance: 'none',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        cursor: 'pointer',
        position: 'relative',
    };

    const checkedCheckboxStyles = {
        ...checkboxStyles,
        backgroundColor: '#4CAF50',
        border: '1px solid #4CAF50',
    };

    const newArtistLayout = () => {


        let paymentStatus = game["payment_status"];
        const teamSelected = (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID");
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        const isUpcoming = start_time > now_time;

        return (
            <div>
                <Col className='ml-auto mr-auto text-center'>
                    {!teamSelected && <p>Select {set_size} Creators to create your team<br></br><strong>({selectArtistCount} out of {set_size} selected)</strong>
                    </p> }
                    {teamSelected && <p>Your team is ready! Monitor your live rank and score on the Leaderboard.
                    </p> }
                    </Col>

                    <Col style={{ padding: "0px" }}>

                    <div style={{ overflow: "scroll" }}>
                        <table className="table" style={{ padding: "5px" }}>
                            <thead>
                            <tr style={{ color: "#8811a5" }}>
                                <th scope="col" style={{ padding: "7px" }}>Select</th>
                                <th scope="col" style={{ padding: "7px" }}>Creator</th>
                                <th scope="col" style={{ padding: "7px" }}>Name</th>
                                <th scope="col" style={{ padding: "7px" }}>Score</th>
                            </tr>
                            </thead>
                            <tbody>
                            {artists.map((artist, index) => {

                                let artist_engagement = artist.engagement_rating;
                                if (isUpcoming === true) {
                                    artist_engagement = artist.avg_followers > 0 ? (artist.avg_likes + 2*artist.avg_comments) / (artist.avg_followers) * 100 : 0;
                                }
                                if (artist_engagement === undefined || artist_engagement === null || artist_engagement === NaN) {
                                    artist_engagement = 0;
                                }
                                let selected_percentage = 0;
                                if (topParticipants.length > 0) {
                                    selected_percentage = (artist.selected_count/topParticipants.length)*100;
                                }
                                let artistsComponent = "artist-component-" + index;

                                    return <tr>
                                         {/*// onClick={(e) => artistSelection(e, index, artist.uuid)}>*/}
                                         <td >
                                            <input type="checkbox"
                                                   style={artist.selected ? checkedCheckboxStyles : checkboxStyles}
                                                   checked={artist.selected}
                                                   onClick={(e) => artistSelection(e, index, artist.uuid)}/>
                                        </td>
                                        <td scope="row">
                                            <img
                                                alt="Con10Craze Fantasy Creators"
                                                style={imageStyles}
                                                src={artist["profile_img"]}
                                            ></img>
                                        </td>
                                        <td>
                                            <a className="artist-name"
                                                    href={"https://instagram.com/" + artist.social_media_handle}
                                                    target="_blank" rel="noopener noreferrer"
                                                    style={{
                                                        display: "flex",
                                                        fontSize: "initial",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                        backgroundClip: "text",
                                                        color: "transparent"
                                                    }}>
                                                {artist.name}</a>
                                            <div style={{color:"grey"}}>Sel by {selected_percentage.toFixed(2)}%</div>
                                        </td>
                                        <td>
                                            <div style={{ animation: "blink 1.5s infinite", color: "green"}} >{artist_engagement.toFixed(2)}</div>
                                            <div style={{ fontSize: "0.75rem" }}>({formatter.format(artist.avg_followers)} Followers)</div>
                                        </td>
                                    </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </div>
        )
    }


    const artistLayout = () => {
        let paymentStatus = game["payment_status"];
        const teamSelected = (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID");
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        const isUpcoming = start_time > now_time;

        return (
            <div className="content">
                <Container>
                    {!teamSelected && <p>Select {set_size} Creators to create your team<br></br><strong>({selectArtistCount} out of {set_size} selected)</strong>
                    </p> }
                    {teamSelected && <p>Your team is ready! Monitor your live rank and score on the Leaderboard.
                    </p> }

{/*                    <Row>

                    <select value={sortOption} onChange={handleSortChange}>
                        <option value="name">Sort by name</option>
                        <option value="age">Sort by age</option>
                        <option value="city">Sort by city</option>
                    </select>
              </Row>
        */}

                    <Row>
                        {artists.map((artist, index) => {
                            let artist_engagement = artist.engagement_rating;
                            if (isUpcoming === true) {
                                artist_engagement = artist.avg_followers > 0 ? (artist.avg_likes + artist.avg_comments) / (artist.avg_followers) * 100 : 0;
                            }
                            if (artist_engagement === undefined || artist_engagement === null) {
                                artist_engagement = 0;
                            }
                            let artistsComponent = "artist-component-" + index;
                            let selectionStyle = artist.selected === true ? "3px solid green" : "1px solid white";
                            let cardStyle = {
                                borderRadius: "20px", border: selectionStyle,
                                backgroundColor: "beige",
                                height: "100%"
                            };

                            return (
                                <Col className="ml-auto mr-auto" xs="6" md="4" key={artist.uuid} id={artistsComponent} style={{ marginBottom: "20px", marginRight: "20px" }}>
                                    <Card className="card-login card-plain" style={cardStyle}>
                                        <CardHeader className="text-center" onClick={(e) => artistSelection(e, index, artist.uuid)}>
                                            <div className="logo-container">
                                                <img
                                                    alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                                                    style={{ borderRadius: "20px", padding: "5px" }}
                                                    src={artist["profile_img"]}
                                                ></img>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="text-center">
                                            <a className="artist-name"
                                                href={"https://instagram.com/" + artist.social_media_handle}
                                                target="_blank" rel="noopener noreferrer"
                                                style={{
                                                    display: "flex",
                                                    fontSize: "initial",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                    backgroundClip: "text",
                                                    color: "transparent"
                                                }}>
                                                {artist.name}</a>
                                            <Row onClick={(e) => artistSelection(e, index, artist.uuid)}>
                                                <Col className="ml-auto mr-auto" xs="12" md="6" style={{ fontWeight: "bold" }}>
                                                    {formatter.format(artist.avg_followers)} Followers
                                                </Col>
                                                <Col className="ml-auto mr-auto" xs="12" md="6" style={{ color: "green", fontWeight: "bold" }}>
                                                    <i className="now-ui-icons media-2_sound-wave" style={{ marginRight: "0.25rem" }}></i>
                                                    {artist_engagement.toFixed(2)}%
                                                    <InfoIcon className="infoIcon" id="engagementTooltip"
                                                        style={{ marginLeft: "3px", marginTop: "-10px", color: "grey", fontSize: "12px", scale: "1.5" }}>
                                                    </InfoIcon>
                                                    <Tooltip
                                                        placement='top'
                                                        isOpen={tooltipOpen}
                                                        target="engagementTooltip"
                                                        toggle={toggleTooltip}
                                                    >
                                                        Creator's latest Engagement Rate
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}

                    </Row>
                </Container>
            </div>
        )
    }

    const winningsLayout = (gameStarted, prizeMoneyToDistribute) => {
        // get winning dsitrbution from game
        let winningDistributionView  = [];
        if (game["details"]["winning_distribution_amounts"]) {
            winningDistributionView = game["details"]["winning_distribution_amounts"];
        } else {
            let distribution = game["details"]["winners_users_distribution"].split`,`.map(x=>+x)
            for ( let i = 0;i < distribution.length; ++i) {
                winningDistributionView.push(distribution[i] + "%")
            }
        }

        return (
            <Row style={{ justifyContent: "center", textAlign: "center", color: "#B00610" }}>
                <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#B00610"
                }}
                    onClick={(e) => {
                        setWinningDistribution(winningDistributionView)
                        setWinningDetails(true);
                    }}> WINNING DISTRIBUTION</p>

                {!gameStarted && 
                <>
                <InfoIcon className="infoIcon" id="engagementTooltipPrize"
                    style={{
                        marginLeft:"2%",
                        color: "grey",
                        fontSize: "12px",
                        scale: "1.5"
                    }}>
                </InfoIcon>
                <Tooltip
                    placement='top'
                    isOpen={tooltipOpenPrize}
                    target="engagementTooltipPrize"
                    toggle={toggleTooltipPrize}
                >The actual winning amount will depend on the final participant count after the game begins.
                </Tooltip></>}
            </Row>
        )


    }

    const handlePlayground = (e) => {
        e.preventDefault();
        navigate("/playground/" + game["details"]["uuid"]);
    }
    const artistsWinningsLayout = (gameStarted, prizeMoneyToDistribute) => {
        // get winning dsitrbution from game
        let winningDistributionView  = [];
        if (game["details"]["artists_winning_distribution_amounts"]) {
            winningDistributionView = game["details"]["artists_winning_distribution_amounts"];
        } else if (game["details"]["winners_artists_distribution"] === undefined || game["details"]["winners_artists_distribution"] === null
            || game["details"]["winners_artists_distribution"].trim().length === 0) {
            return (
                <></>
            )
        } else {
            let distribution = game["details"]["winners_artists_distribution"].split`,`.map(x=>+x)
            for ( let i = 0;i < distribution.length; ++i) {
                winningDistributionView.push(distribution[i] + "%")
            }
        }

        return (
            <Row style={{ justifyContent: "center", textAlign: "center", }}>
                <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#B00610"
                }}
                    onClick={(e) => {
                        setArtistWinningDistribution(winningDistributionView)
                        setArtistWinningDetails(true);
                    }}> CREATORS WINNING DISTRIBUTION</p>

                {!gameStarted && 
                <>
                <InfoIcon className="infoIcon" id="artistEngagementTooltipPrize"
                    style={{
                        marginLeft:"2%",
                        color: "grey",
                        fontSize: "12px",
                        scale: "1.5"
                    }}>
                </InfoIcon>
                <Tooltip
                    placement='top'
                    isOpen={artisttooltipOpenPrize}
                    target="artistEngagementTooltipPrize"
                    toggle={toggleArtistsTooltipPrize}
                >The actual winning amount will depend on the final participant count after the game begins.
                </Tooltip></>}
            </Row>
        )


    }

    const user_leaderboardLayout = () => {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        if (!topParticipants || topParticipants.length === 0) {
            if (start_time > now_time) {
                return (
                    <div>Leaderboard would be updated once the game begins!</div>
                )
            } else {
                return (
                    <div>Leaderboard would be updated soon!</div>
                )
            }
        }
        let showScore = true

        if ( start_time > now_time ) {
            showScore = false
        }
        return (
            <Row className="leaderboard-wrapper">
                <Table id="rankings" className="leaderboard-results" width="100%" rules="row">
                    <thead>
                    <tr>
                        <th>
                            Rank
                        </th>
                        <th>
                            User
                        </th>
                        {showScore && <th>
                            Score
                        </th>}
                        <th>
                            Team Created
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {topParticipants.map((rowData, index) => {
                        return (
                            <tr>
                                <td scope="row">
                                    {index + 1}
                                </td>
                                <td style={{textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}}>
                                    {rowData.first_name}
                                </td>
                                {showScore &&  <td>
                                    {parseFloat(rowData.score).toFixed(2)}
                                </td>
                                }
                                <td>
                                    {format(rowData.team_created_at, 'dd MMMM h:mm a')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            </Row>
        )
    }

    const artist_row = (rowData, index, showScore) => {
        var class_name = index===0?"table-success" : (index === 1? "table-warning" : ( index ===2 ? "table-light" : "table-light"))
        return (
            <tr>
                <td className={`-default ${class_name}`} >
                    {index + 1}
                </td>
                <td className={`-default ${class_name}`} >

                    <a className="artist-name"
                       href={"https://instagram.com/" + rowData.social_media_handle}
                       target="_blank" rel="noopener noreferrer"
                    > {rowData.name} </a>
                </td>
                {showScore &&                <td className={`-default ${class_name}`} >

                {rowData.engagement_rating.toFixed(2)}%
                </td>}
                <td className={`-default ${class_name}`} >

                {rowData.select_by_user_count}
                </td>
            </tr>
        )

    }
    const artist_leaderboardLayout = () => {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        if (!artistsRanking || artistsRanking.length === 0) {
            return (
                <div>Leaderboard would be updated soon!</div>
            )
        }
        let showScore = true

        if ( start_time > now_time ) {
            showScore = false
        }
        return (
            <Row >
                <Table id="rankings" className="table table-bordered" width="100%">
                    <thead>
                    <tr>
                        <th scope="col">
                            #
                        </th>
                        <th scope="col">
                            Artist
                        </th>
                        {showScore && <th scope="col">
                            Engagement Rate
                        </th>}
                        <th scope="col">
                            Selected by users
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {artistsRanking.map((rowData, index) => {
                      return  artist_row(rowData,index,showScore)
                    })}
                    </tbody>
                </Table>
            </Row>
        )
    }


    const details = () => {
        if ("start_time" in game["details"] && "end_time" in game["details"]) {
            let users = game["details"]["max_users"];
            let gameStarted = false;
            if (game["status"] !== "upcoming") {
                users = game["details"]["interested"];
                gameStarted = true;
            }

            let prizeDistribution = Math.floor(users * game["details"]["price"] * game["details"]["winners_users_percent"] / 100);
            return (
                <div color="info" style={{  boxShadow:"0 4px 6px -4px rgba(0, 0, 0, 0.1)",border:"2px solid #dddddd",
                    borderRadius: "20px", padding: "50px 20px", }}>
                    <Col className='ml-auto mr-auto text-center'>
                        <p style={{ fontWeight: "normal" }}>Use a creator's engagement rating statistic to decide your team.</p>
                        <p style={{ fontWeight: "normal" }}>Your score will be updated periodically throughout the course of the game, so keep an eye on the leaderboard to track your latest rank.</p>
                        <p style={{ color: "black", fontWeight: "bold" }}>Game Timings<p style={{ color: "red" }}> {format(new Date(game["details"]["start_time"]), "do MMMM h:mm a")} to {format(new Date(game["details"]["end_time"]), "do MMMM h:mma")}</p></p>
                        {/*                        <p style={{fontWeight:"bold",color:"black"}}>Boosters Allowed<p style={{color:"red"}}> {maxBooster} {maxBooster > 1 ? "boosters" : "booster"}</p></p> */}
                        { !gameStarted && <p style={{ fontWeight: "bold", color: "black" }}>Maximum Participants<p style={{ color: "red" }}>{game["details"]["max_users"]}</p></p>
                        }
                        { gameStarted &&
                            <p style={{ fontWeight: "bold", color: "black" }}>Number of Player playing<p style={{ color: "red" }}>{game["details"]["interested"]}</p></p>
                        }


                        <p style={{ fontWeight: "bold", color: "black" }}>Cash Prize</p>
                        <p style={{color: "red", justifyContent:"center"}} > ₹{prizeDistribution}</p>
                        <Row className="text-center justify-content-center">
                            <p style={{ fontWeight: "bold", color: "black" }}>Content Start Time <p style={{ color: "red" }}>{format(new Date(game["details"]["posts_start_time"]), "do MMMM h:mm a")}</p></p>
                            <p style={{ fontWeight: "bold", color: "black" }}>Content End Time<p style={{ color: "red" }}>{format(new Date(game["details"]["end_time"]), "do MMMM h:mm a")}</p></p>
                        </Row>

                        {winningsLayout(gameStarted, prizeDistribution)}
                        {showPlayersLeaderboard && artistsWinningsLayout(gameStarted, prizeDistribution)}
                    </Col>
                </div>
            )
        }
    }

    function playgroundView() {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        const isUpcoming = start_time > now_time;


        return !isUpcoming && <div className="bottom-right-icon" onClick={handlePlayground}>
            <span className="icon-text" style={{color:"white", fontSize:"10px"}}>LIVE</span>
            {/*<LiveTvIcon style={{fontSize:"40px", color:"black"}} className="blinking-icon"  />*/}
            <span className="icon-text" style={{color:"black", fontSize:"10px"}}>PLAYGROUND</span>
        </div>

    }

    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                {showConfetti && <Confetti height={5000} />}
                <FantasyGameHader game={game} topParticipants={topParticipants} title={title} url={url} />
                <div className="main"></div>

                <div className="section section-tabs" id="tabs-elements">
                    <SEO
                        title="Play Con10Craze Fantasy Sports Games: Create Dream Teams, Engage with Artists, Earn Real Money!"
                        description="Join Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
                        type="website"
                        url={"https://con10craze.com/game/" + game_external_id} />


                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto">

                                { showPodium && <Row>
                                    {showWinners()}
                                </Row>
                                }

                                <Nav className="nav-tabs-neutral justify-content-center" data-background-color="blue" role="tablist" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "1" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "1")}
                                        >
                                            <i className="now-ui-icons users_single-02"></i>
                                            My Team
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "2" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "2")}
                                        >
                                            <i className="now-ui-icons sport_trophy"></i>
                                            Leaderboard
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "3" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "3")}
                                        >
                                            <i className="now-ui-icons travel_info"></i>
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    { showPlayersLeaderboard &&    <NavItem>
                                        <NavLink
                                            className={iconPills === "4" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "4")}
                                        >
                                            <i className="now-ui-icons travel_info"></i>
                                            Players Ranking
                                        </NavLink>
                                    </NavItem>}
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "5" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "5")}
                                        >
                                            <i className="now-ui-icons business_chart-bar-32"></i>
                                            Scorecard
                                        </NavLink>
                                    </NavItem>
                                    { !isUpcoming && <NavItem>
                                        <NavLink
                                            className={iconPills === "6" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "6")}
                                        >
                                            <i className="now-ui-icons media-1_button-play"></i>
                                            Live Playground
                                        </NavLink>
                                    </NavItem>
                                    }
                                </Nav>


                                <CardBody>
                                    <TabContent
                                        className="text-center"
                                        activeTab={"iconPills" + iconPills}
                                    >
                                        <TabPane tabId="iconPills1">
                                            {newArtistLayout()}
                                        </TabPane>
                                        <TabPane tabId="iconPills2">
                                            {user_leaderboardLayout()}
                                        </TabPane>
                                        <TabPane tabId="iconPills3">
                                            {details()}
                                        </TabPane>
                                        { showPlayersLeaderboard && <TabPane tabId="iconPills4">
                                            {artist_leaderboardLayout()}
                                        </TabPane>}
                                        <TabPane tabId="iconPills5">
                                            <Scorecard game_external_id={game_external_id} />
                                        </TabPane>
                                        { !isUpcoming &&<TabPane tabId="iconPills6">
                                            <Playground />
                                        </TabPane>}
                                    </TabContent>
                                </CardBody>
                            </Col>
                        </Row>
                    </Container>

                    {popupNotifications()}

                    <MyModalDialog title="" show={showErrorDialog} onClose={() => { setShowErrorDialog(false); setErrorMsg(null) }}>
                        <Row><Col className="ml-auto mr-auto text-center">
                            {errorMsg && errorHref && <p><a href={errorHref}>{errorMsg}</a></p>}
                            {!errorMsg || !errorHref &&
                            <p style={{color:"#B00610", fontWeight:"bolder"}}>{errorMsg}</p>}
                        </Col></Row>
                    </MyModalDialog>


{/*                    {playgroundView()}*/}

                </div>
                <DarkFooter />
            </div>
        </>
    );
};

export default FantasyGamePage;
