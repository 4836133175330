import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { userService } from "services/user.service";
import { copyTextToClipboard } from "utils/helper";
import ChangePassword from "views/ChangePassword";

const MyProfile = () => {
    const [showChangePwd, setShowChangePwd] = useState(false);
    const userData = userService.getLocalStorageData();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(userData.profile_img);
    const [profileFile, setProfileFile] = useState(null);
    const [firstName, setFirstName] = useState(userData.first_name);
    const [lastName, setLastName] = useState(userData.last_name);
    const [showSave, setShowSave] = useState(false);
    const [isUploading, setisUploading] = useState(false);

    const photoUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (file === null || file === undefined) {
            return;
        }
        const profileImageSize = 3000000; // 3 MB
        if (file.size > profileImageSize) {
            alert("Profile Image cannot be more than 3MB");
            return;
        }
        reader.onloadend = () => {
            setProfileFile(file);
            setShowSave(file !== null && file !== undefined);
            setImagePreviewUrl(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        setFirstName(value);
        setShowSave(userData.first_name !== value);
    }

    const handleLastNameChange = (event) => {
        const value = event.target.value;
        setLastName(value);
        setShowSave(userData.last_name !== value);
    }

    const handleClipBoardClick = (e) => {
        e.preventDefault();
        copyTextToClipboard(userData.referral_link);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setisUploading(true);
        const formData = new FormData();
        if (profileFile !== null && profileFile !== undefined) {
            formData.append('file', profileFile);
        }
        if (firstName !== userData.first_name) {
            formData.append('first_name', firstName);
        }
        if (lastName !== userData.last_name) {
            formData.append('last_name', lastName);
        }
        userService.updatePersonalInfo(formData)
            .then((res) => {
                setisUploading(false);
                setProfileFile(null);
            })
            .catch((err) => {
                setisUploading(false);
            })
        setShowSave(false);
    }

    return (
        <div>
            <Container>
                <Row>
                <Col className="ml-auto mr-auto">

                    <h2>Personal Details</h2>
                    <p style={{ fontWeight: "300" }}>Joined on: {userData.joined_at}</p>

                    <label className="custom-file-upload fas">
                    <div className="img-wrap img-upload" >
                        <img src={imagePreviewUrl} width="auto" height="100%" />
                    </div>
                    <input id="photo-upload" type="file" accept="image/jpeg, image/png" onChange={photoUpload} />
                </label>

                {isUploading && (
                    <div style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Row style={{ backgroundColor: "black", padding: "20px", color: "white", borderRadius: "20px", display: "flex", flexDirection: "row" }}>
                            <CircularProgress thickness={6} size={50} />
                            <h4 style={{ display: "contents" }}>Updating your details...</h4>
                        </Row>
                    </div>
                )}


                    <h5 style={{ fontWeight: "bold", marginTop: "50px" }}>UNIQUE REFERRAL LINK</h5>

                    <Row style={{ backgroundColor: "transparent", padding: "0px", marginLeft: "0px" }}>
                        
                        <p style={{
                            color:"#B00610",width: "auto", margin: "0px", display: "flex", justifyContent: "center",
                            alignItems: "center"
                        }}>{userData.referral_link}</p>
                        <ContentCopyIcon onClick={handleClipBoardClick} style={{ width: "auto", marginLeft: "10px", fontSize: "36px", cursor: "pointer" }} />
                    </Row>

                <h5 style={{ fontWeight: "bold", marginTop: "25px" }}>NAME</h5>
                    <input style={{
                        borderRadius: "15px",
                        border: "1px solid #b7b7b7",
                        padding: "10px",
                        transition: "0.2s",
                    }} placeholder="First Name" value={firstName} onChange={handleFirstNameChange} />

                    <input style={{
                        borderRadius: "15px",
                        border: "1px solid #b7b7b7",
                        padding: "10px",
                        marginTop: "1.4rem",
                        transition: "0.2s"
                    }} placeholder="Last Name" value={lastName} onChange={handleLastNameChange} />

                <h5 style={{ fontWeight: "bold", marginTop: "25px" }}>EMAIL</h5>
                <p>{userData.email}</p>

                <h5 style={{ fontWeight: "bold", marginTop: "25px" }}>PASSWORD</h5>

                <Row>
                    <input type="password" value="************" style={{
                        backgroundColor: "white", margin: "0px", height: "50px", width: "auto",
                        border: "1px solid black", borderRadius: "15px 0px 0px 15px", marginLeft: "0.5rem"
                    }} />

                    <Button onClick={() => setShowChangePwd(true)} style={{
                        margin: "0px", color: "red", border: "1px solid black",
                        backgroundColor: "white", borderRadius: "0px 15px 15px 0px", height: "50px", width: "auto"
                    }}>Change Password</Button>
                </Row>


                {showSave &&
                <div>
                    <Button className='btn-round' color="info" size="lg" style={{ marginTop: "50px", marginLeft: "0px" }} onClick={handleSubmit}>Save</Button>
                </div>
                }

                <ChangePassword showChangePwd={showChangePwd} setShowChangePwd={setShowChangePwd} />
                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyProfile;