import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Alert,
  Row,
  FormGroup,
  Label
} from "reactstrap";

// core components
import { userService } from "services/user.service";
import { isValidEmail, isValidString } from '../utils/helper';
import { loginConversionTag } from "./conversion-script";
import GoogleLogin from 'components/GoogleLogin';
import ReactPasswordChecklist from "react-password-checklist";
import SEO from "utils/seo";

function SignupPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [signupDisabled, setSignUpDisabled] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const referralId = searchParams.get("refer");
  const isArtist = searchParams.get("artist");
  const defaultUserType = isArtist ? "Creator" : "User";
  const [inputs, setInputs] = React.useState({ "password": "", "reenter_password": "", "userType": defaultUserType });
  const location = useLocation();
  const [redirectTo] = React.useState(location.state ? "redirectTo" in location.state ? location.state["redirectTo"] : null : null);
  const [isPwdValid, setIsPwdValid] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (userService.isLoggedIn()) {
      if (isValidString(redirectTo)) {
        navigate(redirectTo);
      } else {
        navigate('/');
      }
    }
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  const handleUserTypeChanged = (event) => {
    let userType = "User";
    if (event.target.name === "Creator") {
      userType = "Creator";
    }
    setInputs((prevState) => {
      return {
        ...prevState,
        ["userType"]: userType
      };
    });
    setErrors((prevState) => {
      return {
        ...prevState,
        ["userType"]: null
      };
    });
  }


  const isValidEmailEntry = () => {
    if (inputs.emailaddress === undefined || inputs.emailaddress === null || inputs.emailaddress.trim().length === 0) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['emailaddress']: 'Please enter email address.'
        };
      });
      return false;
    }
    if (!isValidEmail(inputs.emailaddress)) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['emailaddress']: 'Please enter a valid email address.'
        };
      });
      return false;
    }
    return true;
  }

  const handleChange = (event, name) => {
    const value = event.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
    setErrors((prevState) => {
      return {
        ...prevState,
        [name]: null
      };
    });
  }

  const isValidPasswordMatch = () => {
    if (inputs.password === undefined || inputs.password === null || inputs.password.trim().length === 0) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['password']: 'Please enter a valid password.'
        };
      });
      return false;
    }
    if (inputs.reenter_password === undefined || inputs.reenter_password === null || inputs.reenter_password.trim().length === 0) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['reenter_password']: 'Please confirm the password.'
        };
      });
      return false;
    }
    if (inputs.password != inputs.reenter_password) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['reenter_password']: 'Passwords do not match.'
        };
      });
      return false;
    }
    return true;
  }

  const handleSubmitClick = (event) => {
    if (signupDisabled) {
      return;
    }
    setSignUpDisabled(true);
    event.preventDefault();
    loginConversionTag();
    setErrors({});
    if (!isValidEmailEntry()) {
      setSignUpDisabled(false);
      return;
    }
    if (!isValidPasswordMatch()) {
      setSignUpDisabled(false);
      return;
    }

/*    if (inputs.userType === "Creator" && (inputs["instagramHandle"] === undefined || inputs["instagramHandle"] === null || inputs["instagramHandle"].trim().length === 0)) {
      setErrors((prevState) => {
        return {
          ...prevState,
          ['instagramHandle']: 'Please enter your Instagram handle.'
        };
      });
      setSignUpDisabled(false);
      return;
    }
*/    
    var params = { 'email': inputs.emailaddress.trim(), 'password': inputs.password };
    if (inputs.userType !== undefined && inputs.userType !== null && inputs.userType.trim().length > 0) {
      params["userType"] = inputs.userType;
    }

/*    if (inputs["instagramHandle"] !== undefined && inputs["instagramHandle"] !== null && inputs["instagramHandle"].trim().length > 0) {
      params["instagramHandle"] = inputs["instagramHandle"];
    }*/

    if (referralId !== null && referralId.trim().length > 0) {
      params['referring_uuid'] = referralId.trim();
    }

    userService.signup(params)
      .then(() => {
        navigate('/verify', { state: { email: inputs.emailaddress } });
      })
      .catch(error => {
        setSignUpDisabled(false);
        setErrors((prevState) => {
          return {
            ...prevState,
            ['signup']: error.message
          };
        });
      });
  }

  const handleLogin = (event) => {
    event.preventDefault();
    navigate({ pathname: '/login', search: searchParams.toString(), state: location.state });
  }

  return (
    <div className="page-header clear-filter" filter-color="blue">
      <div className="content">
        <SEO
          title="Sign Up | Create Your Account | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
          description="Sign up and create your account on Con10Craze. Join Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores!"
          type="website"
          url="https://www.con10craze.com/signup" />


        <Container>
          <Col className="ml-auto mr-auto text-center" md="4" style={{ marginTop: "125px" }}>
            <Card className="card-login card-plain">
              <Form action="" className="form" method="">

                <FormGroup tag="fieldset" onChange={handleUserTypeChanged}>
                  <legend>
                    Register as
                  </legend>
                  <FormGroup check>
                    <Input
                      name="User"
                      type="radio"
                      value="User"
                      checked={inputs.userType === "User"}
                    />
                    {' '}
                    <Label check>
                      User
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="Creator"
                      value="Creator"
                      type="radio"
                      checked={inputs.userType === "Creator"}
                    />
                    {' '}
                    <Label check>
                      Creator
                    </Label>
                  </FormGroup>
                </FormGroup>

{/*                {inputs.userType === "Creator" && <><InputGroup
                  className={
                    "no-border input-lg" +
                    (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fab fa-instagram"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your Instagram handle"
                    type="text"
                    value={inputs.instagramHandle}
                    onChange={e => handleChange(e, "instagramHandle")}
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <Alert color="danger" isOpen={errors.instagramHandle !== null && errors.instagramHandle !== undefined}>
                  <Container>
                    {errors.instagramHandle}
                  </Container>
                </Alert>
                </>}*/}


                <GoogleLogin errors={errors} setErrors={setErrors} redirectTo={redirectTo} referralId={referralId} />

                <InputGroup
                  className={
                    "no-border input-lg" +
                    (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your email address"
                    type="email"
                    value={inputs.emailaddress}
                    onChange={e => handleChange(e, "emailaddress")}
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <Alert color="danger" isOpen={errors.emailaddress !== null && errors.emailaddress !== undefined}>
                  <Container>
                    {errors.emailaddress}
                  </Container>
                </Alert>

                <InputGroup
                  className={
                    "no-border input-lg" +
                    (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_lock-circle-open"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={inputs.password}
                    onChange={e => handleChange(e, "password")}
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <Alert color="danger" isOpen={errors.password !== null && errors.password !== undefined}>
                  <Container>
                    {errors.password}
                  </Container>
                </Alert>

                <InputGroup
                  className={
                    "no-border input-lg" +
                    (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_lock-circle-open"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Re-enter Password"
                    type="password"
                    value={inputs.reenter_password}
                    onChange={e => handleChange(e, "reenter_password")}
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <Alert color="danger" isOpen={errors.reenter_password !== null && errors.reenter_password !== undefined}>
                  <Container>
                    {errors.reenter_password}
                  </Container>
                </Alert>

                <Button
                  block
                  className="btn-round"
                  color="info"
                  disabled={!isPwdValid && !signupDisabled}
                  onClick={handleSubmitClick}
                  size="lg"
                >
                  Sign Up
                </Button>

                <Alert color="danger" isOpen={errors.signup !== null && errors.signup !== undefined}>
                  <Container>
                    {errors.signup}
                  </Container>
                </Alert>

                <ReactPasswordChecklist
                  rules={["minLength", "match"]}
                  minLength={8}
                  value={inputs.password}
                  valueAgain={inputs.reenter_password}
                  onChange={(isValid) => { setIsPwdValid(isValid) }}
                  style={{ invalidColor: "#E50914", display: "grid", justifyContent: "center", textAlign: "left", marginTop: "20px" }}
                />

                <div className="pull-center">
                  <h6 style={{
                    fontStyle: "italic",
                  }}>Already have an account?
                    <Button className="btn-link" color="danger" size="lg" onClick={handleLogin}>Log In</Button>
                  </h6>
                </div>

                <div className="pull-center">
                  By signing up, I acknowledge that I have read and agree to Con10Craze's <a href="/terms-of-use" target="_blank">Terms of Service</a> in its entirety.
                </div>

              </Form>
            </Card>
          </Col>
        </Container>
      </div>
    </div>
  );
}

export default SignupPage;
