import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";

// core components

function BannerPageHeader({imageClass= "play-ground-img"}) {
  let pageHeader = React.createRef();
  const navigate = useNavigate();
  const {game_external_id} = useParams();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header page-header-small"  style={{ minHeight:"40vh", maxHeight:"240vh"}}>
        <div
          className={`page-header-image ${imageClass}`}
          ref={pageHeader}
        ></div>

        <Container>
          <div className="playground-header-btn" onClick={(e) => navigate("/game/" + game_external_id)}>
            <i className="now-ui-icons arrows-1_minimal-left"></i>
            <span style={{ marginLeft: "5px" }}>Back To Game</span>
          </div>
        </Container>

      </div>
    </>
  );
}

export default BannerPageHeader;
