/*eslint-disable*/
import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import { Button, Col, Container } from "reactstrap";
import { userService } from "services/user.service";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();
  const navigate = useNavigate();
  const [backgroundImgSize, setBackgroundImgSize] = React.useState("150%");
  const isLoggedIn = userService.isLoggedIn();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
//        setBackgroundImgSize((150 + window.pageYOffset/3).toString() + "%");
  //      pageHeader.current.style.transform =
    //      "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    } else {
      const updateScroll = () => {
        setBackgroundImgSize((150 + window.pageYOffset/3).toString() + "%");
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });


  return (
    <>
      <div className="page-header clear-filter" filter-color="black">
        <div
          className="page-header-image header-img"
          ref={pageHeader}
        ></div>
        <Container>
          <div className="header-btn">
            {!isLoggedIn && <Button className="btn-round hero-img-text-right" size="lg" color="info" style={{ padding:"10px 30px",fontSize:"18px",fontWeight:"bold"}}
              onClick={(e) => navigate("/signup")}>
              Sign Up</Button>}
            {!isLoggedIn && <h5 style={{color:"black", fontWeight:"normal"}}>Sign up now and earn ₹75 instantly</h5>
            }
            {isLoggedIn && <Button className="btn-round hero-img-text-right" size="lg" color="info" style={{ padding:"10px 30px",fontSize:"18px",fontWeight:"bold"}}
              onClick={(e) => navigate("/fantasy-games")}>
              Play Now</Button>}
          </div>
          <div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
