import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

// pages for this kit
import LandingPage from "views/LandingPage.js";
import Faqs from "./Faqs";
import TC from "./TC";
import { HelmetProvider } from "react-helmet-async";
import PrivacyPolicy from "./PrivacyPolicy";
import HeaderLessLayout from "./HeaderLessLayout";
import PageLayout from "./PageLayout";
import PartialPageLayout from "./PartialPageLayout";
import LoginPage from "./LoginPage";
import LoginSuccess from "./LoginSuccess";
import LoginFailed from "./LoginFailed";
import GLoginCallback from "./GLoginCallback";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import ResetPassword from "./ResetPassword";
import SignupPage from "./Signup";
import AllFantasyGames from "./AllFantasyGames";
import FantasyGamePage from "./FantasyGamePage";
import UserProfile from "./UserProfile";
import Verify from "./Verify";
import VerifyEmail from "./VerifyEmail";
import ContactUs from "./ContactUs";
import Blogs from "./Blogs";
import BlogPage from "./BlogPage";
import Promotions from "./Promotions";
import CreatorsStats from "./CreatorsStats";
import Playground from "./Playground";
import BannerPageLayout from "./index-sections/BannerPageLayout";

function App() {
    return (
        <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path='/login' element={<HeaderLessLayout page={LoginPage} />} />
            <Route exact path='/login-success' element={<PartialPageLayout page={LoginSuccess} />} />
            <Route exact path='/login-failed' element={<PartialPageLayout page={LoginFailed} />} />
            <Route exact path='/g/login/callback' element={<PartialPageLayout page={GLoginCallback} />} />
            <Route exact path='/signup' element={<HeaderLessLayout page={SignupPage} />} />
            <Route exact path='/forgot-password' element={<PartialPageLayout page={ForgotPassword} />} />
            <Route exact path='/change-password' element={<PartialPageLayout page={ChangePassword} />} />
            <Route exact path='/reset-password/:token' element={<PartialPageLayout page={ResetPassword} />} />
            <Route exact path='/game/:game_external_id' element={<FantasyGamePage />} />

            <Route path="/contact-us" element={<PartialPageLayout page={ContactUs} />} />
            <Route path="/faqs" element={<PartialPageLayout page={Faqs} />} />
            <Route path="/promotions" element={<PartialPageLayout page={Promotions} />} />
            <Route exact path='/blogs/:public_name' element={<PartialPageLayout page={BlogPage} />} />
            <Route path="/blogs" element={<PartialPageLayout page={Blogs} />} />
            <Route exact path='/verify' element={<PartialPageLayout page={Verify} />} />
            <Route exact path='/verify_email/:token' element={<PartialPageLayout page={VerifyEmail} />} />
            <Route exact path='/privacy-policy' element={<PartialPageLayout page={PrivacyPolicy} />} />
            <Route exact path='/terms-of-use' element={<PartialPageLayout page={TC} />} />
            <Route exact path='/fantasy-games' element={<PartialPageLayout page={AllFantasyGames} />} />
            <Route exact path="/creators" element={<BannerPageLayout page={CreatorsStats} />} />
            <Route exact path="/playground/:game_external_id" element={<BannerPageLayout page={Playground}/>} />
            <Route path="/" element={<PageLayout page={LandingPage} />} />

            <Route exact path="/profile" element={<PartialPageLayout page={UserProfile} />} />      
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
        </HelmetProvider>
      );      
}

export default App;
