import React from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import logo from "assets/img/logo_transparent.png";
import { calculateTotalMoney } from "../../utils/helper";
import { userService } from "services/user.service";
import StreakDialog from "views/index-sections/StreakDialog";

function IndexNavbar({message1, message2}) {
  const navigate = useNavigate();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoggedIn, setIsLoggedIn] = React.useState(userService.isLoggedIn());
  const [showBonusPopup, setShowBonusPopup] = React.useState(true);
  const [bonusClicked, setBonusClicked] = React.useState(false);
  const userData = userService.getLocalStorageData();

  const handleLoginClick = (event) => {
    event.preventDefault();
    document.getElementById('sliderHeaderId').classList.remove('moveslider');
    navigate({ pathname: '/login', search: searchParams.toString() });
  }

  const handleSignupClick = (event) => {
    event.preventDefault();
    document.getElementById('sliderHeaderId').classList.add('moveslider');
    navigate({ pathname: '/signup', search: searchParams.toString() });
  }

  const handleLogout = (event) => {
    event.preventDefault();
    userService.logout();
    setIsLoggedIn(false);
    navigate('/');
  }

  const handleProfileButton = (event) => {
    event.preventDefault();
    navigate('/profile', { state: { 'activeTab': 0 } });
  }

  const handleSettingButton = (event) => {
    event.preventDefault();
    navigate('/profile', { state: { 'activeTab': 1 } });
  }

  const handleNavLinkClick = (toPage) => {
    setCollapseOpen(false);
    navigate({ pathname: toPage, search: searchParams.toString(), state: location.state });
  }

  const notifyForSignupBonus = () => {
    return (
        <div className="game-popup">

            <Alert color="success" isOpen={showBonusPopup && !bonusClicked} >
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons sport_trophy"></i>
                    </div>
                    {' '}<a className="alert-link" style={{ fontSize: "large", cursor: "pointer" }} onClick={handleSignupClick}>Join Now and Get ₹75 Signup Bonus</a>
                    <button
                        type="button"
                        className="close"
                        onClick={(e) => {setBonusClicked(true);setShowBonusPopup(false)}}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
        </div>
    )
}

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    // setTimeout(function () {
    //   setShowBonusPopup(!isLoggedIn);
    // }, 3000);
    
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const handlePromotionClick = (e) => {
    e.preventDefault();
    navigate({ pathname: "/promotions", search: searchParams.toString(), state: location.state });
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="header-info">
        <Container >
          <div className="navbar-translate" style={{ marginTop:"20px",width: "auto" }}>
               <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >

              <img
                  src={logo}
                  width='100'
                  height='95'
                  alt="Con10Craze logo"
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              1st Fantasy Sports with Real Creators & Influencers
            </UncontrolledTooltip>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  onClick={() => handleNavLinkClick('/fantasy-games')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>Fantasy Games</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => handleNavLinkClick('/promotions')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>Promotions</p>
                </NavLink>
              </NavItem>

              <NavItem>
              <NavLink
                  onClick={() => handleNavLinkClick('/faqs')}>
                  <p style={{ color:"black", fontWeight:"bold",cursor: "pointer" }}>FAQ</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.instagram.com/con10craze?ref=webPortal"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i  style={{background: "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)"}} className="fab fa-instagram"></i>
                  <p style={{ color:"#000000", fontWeight:"bold",}} className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/con10craze?ref=webPortal"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i style={{color:"#1DA1F2"}} className="fab fa-twitter"></i>
                  <p style={{  color:"black", fontWeight:"bold"}}className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem className="mr-1">
                <NavLink
                  href="https://www.facebook.com/con10craze.official?ref=webPortal"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i style={{color:"#1877F2"}} className="fab fa-facebook-square"></i>
                  <p style={{  color:"black", fontWeight:"bold"}}className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>

          {showBonusPopup &&
              <div className="game-popup-header" onClick={handlePromotionClick}>
                <div className="promotion-message-container">
                  {/*<div className="alert-icon">*/}
                  {/*    <i className="now-ui-icons sport_trophy"></i>*/}
                  {/*</div>*/}
                  {message1 && <div  className="promotion-message message1">
                    {message1}
                  </div>
                  }
                  {!message1 && <div  className="promotion-message message1">
                    Sign up now and win ₹75 cash bonus instantly.
                  </div>
                  }

                  {message2 && <div  className="promotion-message message2">
                    {message2}
                  </div>
                  }
                  {!message2 && <div  className="promotion-message message2">
                   Refer your friends and both of you will earn ₹50.
                  </div>
                  }
                </div>
              </div>

          }

          {!isLoggedIn &&
            <div>
              <div className="sliderHeader" id="sliderHeaderId"></div>
              <div className="btnHeader">
                <button className="loginHeader" onClick={handleLoginClick}>LOG IN</button>
                <button className="signupHeader" onClick={handleSignupClick}>SIGN UP</button>
              </div>
            </div>
          }

          {isLoggedIn &&
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "white" }}>

              <i className="now-ui-icons icon-wallet-43"></i>
              <CurrencyRupeeIcon style={{color:"black"}} />
              <span style={{ fontWeight: "bold" , color:"black"}}>{calculateTotalMoney(userData)}</span>


              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  color="black"
                  nav
                  className="custom-dropdown-toggle"

                  onClick={(e) => e.preventDefault()}
                >
                  <img src={userData.profile_img} style={{ border:"2px solid #dddddd",borderRadius: "999px"}} width="30" height="30" />
                </DropdownToggle >
                <DropdownMenu   right={true} style={{  boxShadow:" 0 4px 8px rgba(0, 0, 0, 0.1)", border:"2px solid #dddddd",}}>
                  <DropdownItem onClick={handleProfileButton} >
                    <i className="now-ui-icons users_single-02 mr-1"></i>
                    Profile
                  </DropdownItem>
                  <DropdownItem onClick={handleSettingButton}>
                    <i className="now-ui-icons ui-1_send mr-1"></i>
                    Refer
                  </DropdownItem>

                  <DropdownItem onClick={handleSettingButton}>
                    <i className="now-ui-icons ui-1_settings-gear-63 mr-1"></i>
                    Settings
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout}>
                    <i className="now-ui-icons media-1_button-power mr-1"></i>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

            </div>}
        </Container>

      </Navbar>
      <StreakDialog />
    </>
  );
}

export default IndexNavbar;
