import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { userService } from "services/user.service";
import MyModalDialog from './MyModalDialog';


const AdminSection = () => {
    const [userPaymentsData, setUserPaymentsData] = useState([]);
    const [acceptDialog, setAcceptDialog] = useState(false);
    const [upiTxId, setUpiTxId] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [data, setData]= useState({"user_txs": {}});
    const [streakData, setStreakData] = useState({"data": []});

    const [userTxs, setUserTxs] = useState([]);

    useEffect(() => {
        userService.getAdminView()
        .then((res) => {
            setData(res);
        })
        .catch((err) => {})

        fetchUsers();

        userService.getStreakAdminView()
        .then((res) => {
            setStreakData(res);
        })
        .catch((err) => {})
    }, []);

    const fetchUsers = () => {
        userService.getUserPayments()
        .then((res) => {
            setUserPaymentsData(res);
        })
        .catch((err) => {})

        userService.getAdminUserTxs()
        .then((res) => {
            setUserTxs(res);
        })
        .catch((err) => {})
    }

    const acceptPayment = (e, rowData) => {
        setAcceptDialog(true);
        setSelectedRow(rowData);
    }

    const submitAcceptPayment = (e, rowData) => {
        e.preventDefault();
        let upi_tx_id = "";
        userService.acceptUserPayment(selectedRow["id"], selectedRow["uuid"], upiTxId)
        .then((res) => {
            alert(res);
            setAcceptDialog(false);
            fetchUsers();
        })
        .catch((err) => {
            alert(err);
        })
    }

    const warnPayment = (e, rowData) => {
        e.preventDefault();
        userService.warnUserPayment(rowData["id"], rowData["uuid"])
        .then((res) => {
            alert(res);
            fetchUsers();
        })
        .catch((err) => {
            alert(err);
        })
    }

    const rejectPayment = (e, rowData) => {
        e.preventDefault();
        userService.rejectUserPayment(rowData["id"], rowData["uuid"])
        .then((res) => {
            alert(res);
            fetchUsers();
        })
        .catch((err) => {
            alert(err);
        })
    }

    const acceptUserTx = (e, rowData) => {
        e.preventDefault();
        userService.acceptUserTx(rowData["user"], rowData["id"], rowData["amount"])
        .then((res) => {
            alert(res);
            fetchUsers();
        })
        .catch((err) => {
            alert(err);
        })
    }

    const tableData = () => {
        return (
            <Row style={{justifyContent: "center", overflow: "scroll"}}>
                <Col className='ml-auto mr-auto text-center'>
                    <Table className="winning-detail" width="100%">
                        <thead>
                        <tr>
                            <th>
                                Fantasy Game User Id
                            </th>
                            <th>
                                Fantasy Game Name
                            </th>
                            <th>
                                Game Participation Deadline
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                UPI Id
                            </th>
                            <th>
                                Total Price Paid
                            </th>
                            <th>
                                Team Created At
                            </th>
                            <th>
                                Payment Status
                            </th>
                            <th>
                                Previous Warning Sent Time
                            </th>
                            <th>
                                Accept
                            </th>
                            <th>
                                Warning
                            </th>
                            <th>
                                Reject
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {userPaymentsData.map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {rowData["id"]}
                                    </th>
                                    <td>
                                        {rowData["name"]}
                                    </td>
                                    <td>
                                        {rowData["participation_time"]}
                                    </td>
                                    <td>
                                        {rowData["email"]}
                                    </td>
                                    <td>
                                        {rowData["user_upi_id"]}
                                    </td>
                                    <td>
                                        {rowData["total_price"]}
                                    </td>
                                    <td>
                                        {rowData["team_created_at"]}
                                    </td>
                                    <td>
                                        {rowData["status"]}
                                    </td>
                                    <td>
                                        {rowData["warning_sent_time"]}
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="success" type="button"
                                        onClick={(e) => acceptPayment(e, rowData)}
                                        >Accept</Button>
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="warning" type="button"
                                        onClick={(e) => warnPayment(e, rowData)}
                                        >Warning</Button>
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="danger" type="button"
                                        onClick={(e) => rejectPayment(e, rowData)}
                                        >Reject</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const handleUpiTxIdChanges = (e) => {
        e.preventDefault();
        setUpiTxId(e.target.value);
    }

    const userTx = () => {
        return (
            <Row style={{justifyContent: "center", overflow: "scroll"}}>
                <Col className='ml-auto mr-auto text-center'>
                    <Table className="winning-detail" width="100%">
                        <thead>
                        <tr>
                            <th>
                                User Tx Id
                            </th>
                            <th>
                                User Id
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Action
                            </th>
                            <th>
                                UPI Id
                            </th>
                            <th>
                                Amount in INR
                            </th>
                            <th>
                                Created At
                            </th>
                            <th>
                                Payment Status
                            </th>
                            <th>
                                Accept
                            </th>
                            <th>
                                Warning
                            </th>
                            <th>
                                Reject
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {userTxs.map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {rowData["id"]}
                                    </th>
                                    <td>
                                        {rowData["user"]}
                                    </td>
                                    <td>
                                        {rowData["email"]}
                                    </td>
                                    <td>
                                        {rowData["tx_type"]}
                                    </td>
                                    <td>
                                        {rowData["user_upi_id"]}
                                    </td>
                                    <td>
                                        {rowData["amount"]}
                                    </td>
                                    <td>
                                        {rowData["created_at"]}
                                    </td>
                                    <td>
                                        {rowData["tx_status"]}
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="success" type="button"
                                        onClick={(e) => acceptUserTx(e, rowData)}
                                        >Accept</Button>
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="warning" type="button"
                                        >Warning</Button>
                                    </td>
                                    <td>
                                        <Button className="ml-auto mr-auto text-center btn-round" color="danger" type="button"
                                        >Reject</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const streakTableData = () => {
        return (
            <Row style={{justifyContent: "center", overflow: "scroll"}}>
                <Col className='ml-auto mr-auto text-center'>
                    <Table className="winning-detail" width="100%">
                        <thead>
                        <tr>
                            <th>
                                User Id
                            </th>
                            <th>
                                Number of streaks completed
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {streakData["data"].map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {rowData["user"]}
                                    </th>
                                    <td>
                                        {rowData["count"]}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }



    return (
        <div>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" style={{ marginBottom: "30px" }}>
                        Total number of real users: {data["empty_role"]}<br></br>
                        Total number of bot users: {data["bot"]}<br></br>
                        Total number of game users: {data["game_users"]}<br></br>
                        Total number of creators (including created by us): {data["Creator_Request"]}<br></br>
                        Total number of games: {data["total_games"]}<br></br>
                        Total Number of daily streaks completed: {streakData["total_streaks"]} <br></br>
                        {Object.keys(data["user_txs"]).length > 0 && <><br></br>Money Distribution:<br></br> {Object.keys(data["user_txs"]).map((elem, index) => {
                            return <>{elem} - ₹{data["user_txs"][elem]}<br></br></>
                        })} <br></br></>}
                    </Col>
                </Row>
{/*                {streakTableData()}*/}
                <h4>User Game Payments</h4>
                {tableData()}
            </Container>

            <Container>
                <h4>Users Deposit & Withdraw Requests</h4>
                {userTx()}
            </Container>

            <MyModalDialog title="" show={acceptDialog} onClose={() => { setAcceptDialog(false); }}>
                <Row className="ml-auto mr-auto text-center">
                    <p style={{color:"darkred", fontWeight:"bolder"}}>Enter the UPI transaction id created</p>
                    <input type="text" value={upiTxId} placeholder="UPI Transaction Id"
                        onChange={handleUpiTxIdChanges}
                        className="ml-auto mr-auto text-center field center" style={{ textAlign: "center" }}></input>
                </Row>
                <Row className="ml-auto mr-auto text-center">
                    <Button className="ml-auto mr-auto text-center btn-round" size="lg"
                        disabled={upiTxId === null || upiTxId === undefined || upiTxId.trim().length < 1}
                        color="success"
                        type="button"
                        onClick={submitAcceptPayment}
                    >Accept payment</Button>
                </Row>

            </MyModalDialog>
        </div>
    )
}

export default AdminSection;