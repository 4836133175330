import React from "react";
import { faqs } from "assets/data/faq";


const AccordionItem = ({ faq, onToggle, active }) => {
    const { question, answer } = faq;
    return (

<div
      className={"faq " + (active ? "open" : "")}
      onClick={onToggle}
    >
      <div className="faq-question">{question}</div>
      <div className="faq-answer">{answer}</div>
    </div>
    );
   };

const Accordion = () => {
    const [clicked, setClicked] = React.useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
         return setClicked("0");
        }
        setClicked(index);
       };
      
       return (
        <ul className="faqs">
         {faqs.map((faq, index) => (
          <AccordionItem key={index} faq={faq}
           onToggle={() => handleToggle(index)}
           active={clicked === index}
           />
         ))}
        </ul>
       );
    };

export default Accordion;