import { format } from "date-fns";
import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
import { MyTimer } from "utils/helper";
import MyModalDialog from "views/index-sections/MyModalDialog";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { defaultProfileImage } from "utils/constants";

// core components
import games from "../../assets/img/game.jpg";
import how_to_thumb from "assets/img/how_to_thumb.png";

function FantasyGameHader({ game, topParticipants, title, url }) {
    let pageHeader = React.createRef();
    const howToPlayVideoSrc = "https://d78eqrbdvopvl.cloudfront.net/how_to_play.mp4";
    const [showHowToPlay, setShowHowToPlay] = React.useState(false);
    const [showShare, setShowShare] = React.useState(false);
    const social_hashtag = "con10craze";
    const social_body = "Try & beat me in the Con10Craze Fantasy Sports Game!";

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    const gameTimeAndUser = () => {
        var endDate = new Date(game["details"]["end_time"]);

        return (
            <div style={{ marginTop: "4rem" }}>
                <Col>
                    {game.status === "upcoming" &&
                        <h3 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}>Get ready for <br></br><span className="title">{game["details"]["name"]}</span></h3>
                    }
                    {game.status === "upcoming" &&
                        <h5 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}>
                            Starts in:&nbsp;<MyTimer expiryTimestamp={new Date(game["details"]["start_time"])}></MyTimer></h5>
                    }
                    {game.status === "live" &&
                        <h3 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}><span className="title">{game["details"]["name"]}</span> is now LIVE!</h3>
                    }
                    {game.status === "completed" &&
                        <h3 className="center ml-auto mr-auto" style={{ marginBottom: "0px" }}><span className="title">{game["details"]["name"]}</span> has completed!</h3>
                    }
                    {game.status === "live" && game["details"]["end_time"] !== undefined &&
                        <p className="center ml-auto mr-auto" style={{ fontWeight: "500" }}>Ends at: {format(endDate, 'dd MMMM h:mm a')}</p>
                    }
                    {game.status === "completed" && game["details"]["end_time"] !== undefined &&
                        <p className="center ml-auto mr-auto" style={{ fontWeight: "500" }}>Ended at: {format(endDate, 'dd MMMM h:mm a')}</p>
                    }
                    <h5 className="ml-auto mr-auto">Entry fee: <span className="title" style={{color:"#B00610"}}>₹{game["details"]["price"]}</span></h5>
                </Col>
            </div>
        )
    }

    const numberFromText = (text) => {
        // numberFromText("AA");
        const charCodes = text
          .split('') // => ["A", "A"]
          .map(char => char.charCodeAt(0)) // => [65, 65]
          .join(''); // => "6565"
        return charCodes;
      }

    const gamePlayers = () => {
        const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A', '#A456E3', 'red', 'purple', 'green', 'grey', 'magenta'];
        return (
            <div>
                {topParticipants.length > 0 &&
                    <Row>
                        <Col className="ml-auto mr-auto text-center">
                            {topParticipants.map((joinedUser, index) => {
                                let bgColor = colors[numberFromText(joinedUser["first_name"]) % colors.length]; // => "#DC2A2A"

                                if (index < 5) {
                                    let userProfileImg = joinedUser["profile_img"];
                                    if (userProfileImg === null || userProfileImg === undefined) {
                                        return (
                                            <span className="avatar">
                                                <div class="default-avatar" width="60px" height="60px" style={{ backgroundColor: bgColor}}>{joinedUser["first_name"][0]}</div>
                                            </span>
                                        )
                                    }
                                    else return (
                                        <span className="avatar">
                                            <img src={userProfileImg}
                                                width="60px" height="60px" />
                                        </span>
                                    )
                                }
                                else if (index == 5) {
                                    return (
                                        <span className="avatar" style={{ fontSize: "large" }}>
                                            <AddIcon width="60px" height="60px" fontSize="large"/>
                                            &nbsp;{topParticipants.length - 5} more
                                        </span>
                                    )
                                }
                            })
                            }
                            <p style={{ marginTop: "10px" }}>{topParticipants.length} Playing</p>
                        </Col>
                    </Row>}

            </div>
        )
    }

    return (
        <>
            <div className="page-header page-header-xsmall">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: `url(${games})`
                    }}
                    ref={pageHeader}
                ></div>
                <div className="content-center" style={{marginTop:"0%"}}>
                    <Container>

                        {gameTimeAndUser()}

                        <MyModalDialog title="How to Play Con10Craze Fantasy Sports Games" show={showHowToPlay} onClose={() => setShowHowToPlay(false)}>
                            <video id="teaser_vid"
                                preload="none"
                                poster={how_to_thumb}
                                frameBorder="0"
                                playsInline
                                controls
                                width="100%"
                                title="How to Play Con10Craze Fantasy Sports"
                            >
                                <source src={howToPlayVideoSrc} type="video/mp4" />
                            </video>
                        </MyModalDialog>

                        <MyModalDialog title="Invite your friends" show={showShare} onClose={() => setShowShare(false)}>
                            <div className="text-center">

                                <p style={{ fontSize: "500" }}>Double your fun by competing with your friends!</p>


                                <div className="share-container" style={{
                                }}>
                                    <div className="share">
                                        <FacebookShareButton className='icon first react_share_button'
                                            url={url}
                                            title={social_body}
                                        >
                                            <FacebookIcon size={36} round={true} />
                                        </FacebookShareButton>

                                        <TwitterShareButton className='icon react_share_button'
                                            url={url}
                                            title={social_body}
                                            hashtags={[social_hashtag]}
                                        >
                                            <TwitterIcon size={36} round={true} />
                                        </TwitterShareButton>

                                        <LinkedinShareButton className='icon react_share_button'
                                            url={url}
                                        >
                                            <LinkedinIcon size={36} round={true} />
                                        </LinkedinShareButton>

                                        <RedditShareButton className='icon react_share_button'
                                            url={url}
                                            title={social_body}
                                        >
                                            <RedditIcon size={36} round={true} />
                                        </RedditShareButton>

                                        <WhatsappShareButton className='icon last react_share_button'
                                            url={url}
                                            title={social_body}
                                            separator=":: "
                                        >
                                            <WhatsappIcon size={36} round={true} />
                                        </WhatsappShareButton>
                                    </div>
                                </div>
                            </div>

                        </MyModalDialog>

                        <a
                            className="howto_float"
                            onClick={() => setShowHowToPlay(true)}
                        >
                            <i className="now-ui-icons media-1_button-play"></i>
                        </a>

                        <a
                            className="share_float"
                            onClick={() => setShowShare(true)}
                        >
                            <i className="now-ui-icons arrows-1_share-66"></i>
                        </a>
                    </Container>
                </div>

            </div>
        </>
    );
}

export default FantasyGameHader;
