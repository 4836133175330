export const localBaseUrl = "http://localhost:5000/";
export const prodBaseUrl = "https://cinemakrazy.us-east-1.elasticbeanstalk.com/";
export const baseUrl = prodBaseUrl;
export const idleTimeout = 1000 * 5 * 60;
export const loginWaitTime = 3000; // 3 seconds
export const localStripePublicKey = "pk_test_51MuaObSCDBcJ4QxWVGMQOeHz24o6aQ1stSRSDuipDz8hMHngQYWKd217aiNgpJyBfFi9VhwbEpL8fXxssiwRwMBB00hqrkSn6z";
export const productionStripePublicKey = "pk_live_51MuaObSCDBcJ4QxWfWKzxFGBrbvUELXWipEF0QX0mbBgnptxm1DqC3eFz6cqIKJPzmLKyhC43mPB1U1qlj7rt1nE00BJFcWF4s";
export const baseStripePublicKey = productionStripePublicKey;

export  const instagramGradientBoxColor = "#000000";
export const defaultProfileImage = "https://d78eqrbdvopvl.cloudfront.net/profile_img_template.png";
export const artistRoleName = "Creator_Request";